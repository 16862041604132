export const buildURIByObject = params => {
  const paramsNames = Object.keys(params)
  let uri = ''

  paramsNames.forEach((name, index) => {
    uri += index === (paramsNames.length - 1)
      ? `${name}=${params[name]}`
      : `${name}=${params[name]}&`
  })

  return uri
}

export const makeURIArray = (key, values) => {
  let queryString = ''

  if (typeof values === 'object') {
    values.forEach((value, index) => {
      queryString += `${key}[]=${value}`

      if (index !== values.length - 1) {
        queryString += '&'
      }
    })
  }

  return queryString
}
