import React, { Component, Suspense } from 'react'
import { arrayOf, number, shape, string, bool, func, oneOfType, node, object } from 'prop-types'
import '../../assets/smart-table.scss'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Input,
  Menu,
  Dropdown,
  Button,
  Select,
  Popconfirm,
  Switch,
  Tooltip,
  Tag, Form
} from 'antd'
import { downloadCSV, canonizeString } from '../../utils'
import {
  mapStateToProps,
  mapDispatchToProps,
  connect
} from '../../reducers/Dispatchers'
import PositiveMatchEmailingCheck from './PositiveMatchEmailingCheck'
import HintedSwitch from '../antd/switch/HintedSwitch'
import Selector from './Selectors/Selector'
import { isFunction } from 'lodash'

const localStorage = window.localStorage

// use React.lazy to load the student validation component and to have code splitting
const SatisfactionRatio = React.lazy(() => import('./SatisfactionRatio'))
const SatisfactionRatioSmartTableFilter = React.lazy(() => import('./SatisfactionRatioSmartTableFilter'))

const Option = Select.Option
const Search = Input.Search

const linesShown = [
  { menuKey: 'smart-table-lines-shown-10', value: 10, caption: 'lines' },
  { menuKey: 'smart-table-lines-shown-20', value: 20, caption: 'lines' },
  { menuKey: 'smart-table-lines-shown-50', value: 50, caption: 'lines' },
  { menuKey: 'smart-table-lines-shown-100', value: 100, caption: 'lines' }
]
const linesDensity = [
  {
    menuKey: 'smart-table-lines-density-32',
    value: 32,
    caption: 'High density'
  },
  {
    menuKey: 'smart-table-lines-density-40',
    value: 40,
    caption: 'Medium density'
  },
  { menuKey: 'smart-table-lines-density-48', value: 48, caption: 'Low density' }
]

const DATA_TYPE_ID = 0
const DATA_TYPE_STRING = 1
const DATA_TYPE_DATE = 2
const DATA_TYPE_SELECT = 3
const DATA_TYPE_BOOLEAN = 4
const DATA_TYPE_BOOLEAN_TAG = 5
const DATA_TYPE_SATISFACTION_RATIO = 6
const DATA_TYPE_IMAGE = 7
const DATA_TYPE_TAGS_LIST = 8
const DATA_TYPE_CHECK = 9
const DATA_TYPE_HINTED_BOOLEAN = 10
const DATA_TYPE_SELECTOR = 11

/**
 * !!!!!! DOCUMENTATION !!!!!!!
 * -------------31.03.2020------------------
 * It's possible to load dynamic data on selects based on the selection of a parent select item
 * this is used for teh users table when the connected user has ROLE_COORDINATOR, we have 2 selects:
 * one for Institution (the parent) and one for Sector (child), I created new column properties (see below):
 * `parentOf`, `loadChildData`.
 * But for dynamic selects the actual options will be found inside the data item not inside the columns options,
 * There are the following conventions (example for the data property named `sector`):
 * `sectorOptions` -> here we have the dynamic array of options for select
 * `sectorDisabled` -> if this is true the select will be disabled, so in this case we don't need the column.disabled property
 * -------------------------------------------
 */
class SmartTable extends Component {
  static propTypes = {
    /**
     * For all the ids in this array we don't show the delete button
     */
    hideDeleteButtonForIds: arrayOf(number),
    /**
     * secondaryButtons -> this is an array of elements: additional buttons rendered under the main add button
     */
    secondaryButtons: arrayOf(node),
    showSecondaryButtonsInFront: bool,
    columns: arrayOf(shape({
      /**
       * See DATA_TYPE_XXX details above
       */
      type: number,
      /**
       * name -> Title of column
       */
      name: string,
      key: string,
      options: arrayOf(shape({
        id: oneOfType([number, string]),
        name: string
      })),
      disabled: bool,
      validate: func,
      password: bool,
      /**
       * parentOf -> used on parent selects here we set the name key name of the child
       */
      parentOf: string,
      /**
       * loadChildData -> used on parent selects, this is a promise which is called as soon as
       * the value changes on parent. It will load the options for child which is defined in `parentOf`
       */
      loadChildData: func,
      /**
       * preventAutoSelectDefaultValue -> set this to true if you don't want to auto load the first option for selects
       */
      preventAutoSelectDefaultValue: bool,
      /**
       * enableCreation -> thi sis used for selects, if it's true, a button appears at the end of the options list
       */
      enableCreation: bool,
      /**
       * newItemComponent -> this is a react element which is rendered at the bottom of a slect list
       */
      newItemComponent: node,
      /**
       * onCreateNewItem -> is a callback executed when the last item (newItemComponent) is selected in a select list
       */
      onCreateNewItem: func,
      /**
       * keepTableDisplayedWhenNoData -> if you set this to true the table columns & header will remain visible even when there's no data.
       * This is useful when filtering
       */
      keepTableDisplayedWhenNoData: bool,
      /**
       * This executes the onDataAdd callback without adding a new row in the table if value is false
       */
      addLineOnAddData: bool,
      /**
       * Set this to true if you don't want to see the saved / notSaved icon
       */
      hideSavedStatusIcon: bool,
      /**
       * if addDataRow object has values it will auto show a new data row populated with the values in this object
       */
      addRowData: object
    }))
  };

  static defaultProps = {
    hideDeleteButtonForIds: [],
    hideSavedStatusIcon: false
  };

  state = {
    actionsColumnWidth: 0,
    columns: [],
    data: [],
    errorMessage: '',
    filter: '',
    focusedLineId: null,
    linesDensity: linesDensity[0].value,
    linesShown: linesShown[0].value,
    newDataPending: false,
    pageIndex: 0,
    sortBy: null,
    sortDirection: 1,
    tableTotalWidth: 0,
    isEditable: false,
    keepTableDisplayedWhenNoData: false
  };

  // satisfactionRatioInstances will contain close methods for each satisfaction ratio column
  satisfactionRatioInstances = {};

  // filter parameters saved to cache
  settingsInCache = {}

  componentWillMount () {
    this.getSettingsInCache()
  }

  componentDidMount () {
    this.savePropsToState(this.props)

    if (this.props.defaultSortBy) {
      this.setState({
        sortBy: this.props.defaultSortBy,
        sortDirection: this.props.defaultSortDirection ?? 1
      })
    }

    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillReceiveProps (nextProps) {
    this.savePropsToState(nextProps)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    clearTimeout(this.timerBeforeUpdate)
    this.timerBeforeUpdate = setTimeout(() => this.updateTableWidth(), 250)
  };

  savePropsToState = async props => {
    const { columns, data, addRowData } = props

    // Copying column state into new props.
    const stateColumns = columns.map(c => {
      if (c.key === 'shown') throw Error('"shown" is a reserved word.')
      if (c.key === 'dirty') throw Error('"dirty" is a reserved word.')
      if (c.key === 'width') throw Error('"width" is a reserved word.')
      if (c.key === 'correct') throw Error('"correct" is a reserved word.')
      if (c.key === 'dataValidation') { throw Error('"dataValidation" is a reserved word.') }

      const prevColumn = this.state.columns.filter(prev => prev.key === c.key)
      if (prevColumn.length === 1) {
        c.shown = prevColumn[0].shown
      } else {
        c.shown = true
        if (!c.width) {
          c.width = -1 // To be set in a next update (when user can resize column).
        }
      }

      if (c.key === 'id' && !c.keepShown) {
        c.shown = false
      }

      return c
    })

    // Copying data state into new props.
    const newData = data.map(d => {
      const prevData = this.state.data.filter(prev => prev.id === d.id)
      if (prevData.length === 0) {
        d.shown = true
        d.dirty = false
      } else {
        d.shown = prevData[0].shown
        d.dirty = prevData[0].dirty
      }
      // Preparing data validation object.
      this.prepareDataValidation(d)
      return d
    })

    let actionsCount = 0
    if (
      typeof this.props.onDataDelete !== 'undefined' ||
      this.state.newDataPending
    ) { actionsCount++ }
    if (
      typeof this.props.onDataEdit !== 'undefined' ||
      this.state.newDataPending
    ) { actionsCount++ }
    if (this.props.additionalActions) { actionsCount += this.props.additionalActions.length }
    // Each button is 34px wide + 2px left margin (= 36)
    // The TD has 3px left/right padding + 1px right border (= 7).
    const actionsColumnWidth = actionsCount * 36 + 7

    let isEditable = false
    const { onDataAdd, onDataEdit, onDataDelete, hideSavedStatusIcon } = this.props
    if ((typeof onDataAdd === 'function' || onDataEdit === 'function' || onDataDelete === 'function') && hideSavedStatusIcon !== true) {
      isEditable = true
    }
    await this.setState({
      columns: stateColumns,
      data: newData,
      newDataPending: false,
      actionsColumnWidth: actionsColumnWidth,
      loading: false,
      focusedLineId: null,
      isEditable
    })
    await this.filterData()
    this.columnSort()

    if (addRowData) {
      this.handleAddData()
    }
  };

  // shared function by edit/create methods to validate the fields
  prepareDataValidation = (data) => {
    const { columns } = this.props
    const dataValidation = {}
    for (let i = 0; i < columns.length; i++) {
      if (
        typeof columns[i].validate !== 'undefined' &&
        (!columns[i].disabled)
      ) {
        dataValidation[columns[i].key] = columns[i].validate(
          data[columns[i].key]
        )
      }
    }
    data.dataValidation = dataValidation
    const dataValidationValues = Object.values(data.dataValidation).filter(
      validValue => !validValue
    )
    data.correct = dataValidationValues.length === 0
  }

  updateTableWidth = () => {
    if (typeof this.refs.smartTable !== 'undefined') {
      this.setState({
        tableTotalWidth: this.refs.smartTable.getBoundingClientRect().width
      })
    }
  };

  getSettingsInCache = () => {
    this.settingsInCache = JSON.parse(localStorage.getItem('settings')) ?? {
      columnsShown: [false, true, true, true, true, true, true, true, true],
      linesShown: 20,
      pageIndex: 0,
      linesDensity: 32
    }

    this.setState({
      columns: this.settingsInCache.columnsShown,
      linesShown: this.settingsInCache.linesShown,
      pageIndex: this.settingsInCache.pageIndex,
      linesDensity: this.settingsInCache.linesDensity
    })
  }

  setSettingsInCache = () => {
    this.settingsInCache.columnsShown = this.state.columns.map(c => c.shown)
    this.settingsInCache.linesShown = this.state.linesShown
    this.settingsInCache.pageIndex = this.state.pageIndex
    this.settingsInCache.linesDensity = this.state.linesDensity
    localStorage.setItem('settings', JSON.stringify(this.settingsInCache))
  }

  toggleColumnVisibility = key => {
    let columns = this.state.columns
    columns = columns.map(c => {
      if (c.key === key) c.shown = !c.shown

      return c
    })

    this.setState({ columns: columns }, () => this.setSettingsInCache())
  };

  tableSettingsItemClick = async menuKey => {
    if (menuKey.indexOf('smart-table') === -1) {
      await this.toggleColumnVisibility(menuKey)
      this.filterData()
      return
    }

    const newLinesShown = linesShown.filter(ls => ls.menuKey === menuKey)
    if (newLinesShown.length === 1) {
      this.setState({
        linesShown: newLinesShown[0].value,
        pageIndex: 0
      }, () => this.setSettingsInCache())
      return
    }

    const newLinesDensity = linesDensity.filter(ld => ld.menuKey === menuKey)
    if (newLinesDensity.length === 1) {
      this.setState({
        linesDensity: newLinesDensity[0].value
      }, () => this.setSettingsInCache())
      return
    }

    throw Error('Unknow menu item')
  };

  generateExport = () => {
    const columns = this.state.columns

    return this.state.data.filter(d => d.shown).map(d => {
      const newData = {}

      columns.forEach(c => {
        if (c.shown) {
          if (c.type === DATA_TYPE_BOOLEAN) {
            newData[c.name] = d[c.key] ? this.props.t('Oui') : this.props.t('Non')
          } else if (c.type === DATA_TYPE_DATE && c.name === this.props.t('Last login')) {
            const lastConnectionDay = moment(d[c.key] ?? null, 'DD/MM/YYYY')
            const currentDay = moment()

            newData[`${c.name} (${this.props.t('date')})`] = d[c.key] ?? ''
            newData[`${c.name} (${this.props.t('days')})`] = d[c.key] ? currentDay.diff(lastConnectionDay, 'days') : ''
          } else if (c.type === DATA_TYPE_SELECT) {
            if (c.key) {
              const optionName = c.options.filter(o => o.id === d[c.key])

              if (optionName && optionName.length > 0) {
                newData[c.name] = optionName[0].name
              } else {
                let cellValue = ''
                // it means this select is a dynamic one. Let's check the data
                const options = d[`${c.key}Options`]
                if (options) {
                  const selectedOption = options.find(item => item.id === d[c.key])
                  if (selectedOption) {
                    cellValue = selectedOption.name
                  }
                }
                newData[c.name] = cellValue
              }
            } else {
              newData[c.name] = ''
            }
          } else if (c.type === DATA_TYPE_STRING) {
            let value = ''

            if (d[c.key]) {
              value = d[c.key].toString().replace('#', '//').replace(';', '')

              if (value.match(',|:')) {
                value = `"${value}"`
              }
            }

            newData[c.name] = value
          } else {
            newData[c.name] = d[c.key] ?? ''
          }
        }
      })

      return newData
    })
  };

  // This is a callback which is sent to parent components in `onDataAdd` or `onDataEdit`
  stopLoading = () => {
    this.setState({ loading: false })
  }

  saveObject = async object => {
    if (!object || !object.correct || !object.dirty) {
      return
    }

    const data = this.state.data.map(d => {
      if (d.id === object.id) d.dirty = false
      return d
    })
    this.setState({
      data: data,
      loading: true
    })

    const columns = this.state.columns

    const objectCopy = {}
    for (let i = 0; i < Object.keys(object).length; i++) {
      const key = Object.keys(object)[i]
      if (
        key !== 'shown' &&
        key !== 'dirty' &&
        key !== 'correct' &&
        key !== 'dataValidation'
      ) {
        objectCopy[key] = object[key]
      }
    }

    for (let i = 0; i < columns.length; i++) {
      if ((!isFunction(columns[i].disabled) && columns[i].disabled)) delete objectCopy[columns[i].key]
    }

    if (objectCopy.id === -1) {
      delete objectCopy.id
      /**
       * send the stop loading callback as a param, if there's an error saving the new object
       * we can stop the loading indicator
       */
      this.props.onDataAdd(objectCopy, this.stopLoading)
    } else {
      this.props.onDataEdit(objectCopy, this.stopLoading)
    }
  };

  updateErrorMessage = object => {
    if (!object.correct) {
      const incorrectFields = Object.keys(object.dataValidation).filter(
        dv => !object.dataValidation[dv]
      )
      let errorMessage = ''

      if (incorrectFields.length > 1) {
        errorMessage =
          this.props.t(
            'The next fields need to be corrected before being able to save this line'
          ) + ' : '
      } else {
        errorMessage =
          this.props.t(
            'The next field needs to be corrected before being able to save this line'
          ) + ' : '
      }

      const incorrectFieldsName = incorrectFields.map(i =>
        this.props.t(
          this.state.columns.filter(c => c.key === i)[0].name.toLowerCase()
        )
      )
      if (incorrectFieldsName.length > 1) {
        incorrectFieldsName[incorrectFieldsName.length - 1] =
          this.props.t('and') +
          ' ' +
          incorrectFieldsName[incorrectFieldsName.length - 1]
      }
      this.setState({
        errorMessage: errorMessage + incorrectFieldsName.join(', ') + '.',
        placement: 'bottomRight'
      })
    } else {
      this.setState({ errorMessage: '' })
    }
  };

  handleAddData = () => {
    if (
      typeof this.props.addLineOnAddData !== 'undefined' &&
      !this.props.addLineOnAddData
    ) {
      this.props.onDataAdd()
      return
    }

    const data = this.state.data
    const columns = this.state.columns
    if (data.filter(d => d.id === -1).length === 0) {
      const newData = {
        id: -1,
        shown: true,
        dirty: true
      }

      for (let i = 0; i < columns.length; i++) {
        if (columns[i].type === DATA_TYPE_ID) newData[columns[i].key] = -1
        if (columns[i].type === DATA_TYPE_BOOLEAN || columns[i].type === DATA_TYPE_HINTED_BOOLEAN) {
          if (this.props.addRowData && this.props.addRowData[columns[i].key]) {
            newData[columns[i].key] = this.props.addRowData[columns[i].key]
          } else {
            newData[columns[i].key] = !!this.props.reverseDefaultBoolean
          }
        }
        if (columns[i].type === DATA_TYPE_DATE) {
          if (this.props.addRowData && this.props.addRowData[columns[i].key]) {
            newData[columns[i].key] = this.props.addRowData[columns[i].key]
          } else {
            newData[columns[i].key] = ''
          }
        }
        if (columns[i].type === DATA_TYPE_SELECT) {
          if (this.props.addRowData && this.props.addRowData[columns[i].key]) {
            const column = columns[i]
            const value = this.props.addRowData[column.key]
            newData[column.key] = this.props.addRowData[column.key]
            if (typeof (column.loadChildData) === 'function') {
              column.loadChildData(value).then(options => {
                const optionsPropertyName = `${column.parentOf}Options`
                newData[optionsPropertyName] = options
                if (this.props.addRowData[column.parentOf]) {
                  newData[column.parentOf] = this.props.addRowData[column.parentOf]
                }
              })
            }
          } else {
            newData[columns[i].key] =
              typeof columns[i].options[0] === 'undefined' || columns[i].preventAutoSelectDefaultValue
                ? null
                : columns[i].options[0].id
          }
        }
        if (columns[i].type === DATA_TYPE_STRING) {
          if (this.props.addRowData && this.props.addRowData[columns[i].key]) {
            newData[columns[i].key] = this.props.addRowData[columns[i].key]
          } else {
            newData[columns[i].key] = ''
          }
        }
      }

      // Preparing data validation object.
      setTimeout(() => {
        this.prepareDataValidation(newData)

        this.updateErrorMessage(newData)
        data.unshift(newData)
        this.setState({
          data: data,
          newDataPending: true,
          pageIndex: 0
        })
      }, 0)
    }
  };

  updateDataValue = (prevData, key, value) => {
    const updatedData = this.state.data.slice()
    const column = this.state.columns.filter(d => d.key === key)[0]

    if (column.loadMessage) {
      this.props.loadMessageFieldsTypes({
        content: column.loadMessage.content,
        key: 'updatable',
        duration: column.loadMessage.duration
      })
    }

    if (column.parentOf) {
      const columns = this.state.columns.slice()
      const childColumn = columns.find(item => item.key === column.parentOf)
      if (childColumn) {
        // now check if the parent column has a function to load data in the second column
        if (typeof column.loadChildData === 'function') {
          column.loadChildData(value).then(options => {
            const itemToUpdate = updatedData.find(item => item.id === prevData.id)

            if (itemToUpdate) {
              itemToUpdate.disabled = value <= 0
              if (itemToUpdate[`${column.parentOf}Disabled`] !== undefined && itemToUpdate[`${column.parentOf}Disabled`] !== null) {
                itemToUpdate[`${column.parentOf}Disabled`] = itemToUpdate.disabled
              }
              /**
               * this is a dynamic select which depends on the selection made in the parent
               * In this case we store the options at data level with property name format: propertyOptions
               */
              const optionsPropertyName = `${column.parentOf}Options`
              itemToUpdate[optionsPropertyName] = options
              // and switch the previous value of the child to -1 forcing the user to select again
              if (itemToUpdate[column.parentOf] !== undefined) {
                itemToUpdate[column.parentOf] = -1
              }
              this.prepareDataValidation(itemToUpdate)
              this.updateErrorMessage(itemToUpdate)
            }
            this.setState({ columns })
          })
        } else {
          this.setState({ columns })
        }
      }
    }

    updatedData.forEach(d => {
      if (prevData.id === d.id) {
        d[key] = value
        d.dirty = true

        if (
          typeof column.validate !== 'undefined' ||
          column.type === DATA_TYPE_DATE
        ) {
          const dataValidation = d.dataValidation
          let dateValid = true

          if (column.type === DATA_TYPE_DATE && !(column.isNullable && !value)) {
            dateValid = moment(value, column.format, true).isValid()
          }

          if (typeof column.validate !== 'undefined') {
            dataValidation[key] = column.validate(value) && dateValid
          }

          d.dataValidation = dataValidation
          d.correct = Object.values(d.dataValidation).filter(d => !d).length === 0

          this.updateErrorMessage(d)
        }

        if (typeof column.postChange === 'function') {
          column.postChange(d)
        }
      }
    })

    this.setState({ data: updatedData })
  };

  deleteData = data => {
    if (data.id === -1) {
      this.setState({
        data: this.state.data.filter(d => d.id !== data.id),
        newDataPending: false
      })
    } else {
      this.props.onDataDelete(data, this.stopLoading)
    }
  };

  renderTable = (columns, data) => {
    if (columns.filter(c => c.shown).length === 0) {
      return (
        <div className='v-spacing'>
          {this.props.t('Table empty, please display at least one column.')}
        </div>
      )
    }

    return (
      <table className='table'>
        <thead>{this.renderTableHead(columns)}</thead>
        <tbody>{this.renderTableBody(columns, data)}</tbody>
      </table>
    )
  };

  renderTableHead = columns => {
    const content = columns.filter(c => c.shown).map(c => {
      const className = this.state.sortBy === c.key ? this.state.sortDirection === 1 ? 'order-up' : 'order-down' : 'inherit'

      const content = (
        <div className={c.enableFiltering ? 'smart-table-column-header' : ''}>
          {c.enableFiltering && <div className='column-label'>{c.name}</div>}
          {!c.enableFiltering && c.name}
          {c.type === DATA_TYPE_SATISFACTION_RATIO && c.enableFiltering &&
            <Suspense fallback={<div>Loading...</div>}>
              <SatisfactionRatioSmartTableFilter
                id={c.key}
                onDisplay={(id, closeFilterPopupMethod) => {
                  for (const [key, closeMethod] of Object.entries(this.satisfactionRatioInstances)) {
                    if (key !== id) {
                      // execute the close method
                      closeMethod()
                    }
                  }

                  if (!this.satisfactionRatioInstances[id]) {
                    this.satisfactionRatioInstances[id] = closeFilterPopupMethod
                  }
                }}
              />
            </Suspense>}
        </div>
      )
      return (
        <th
          key={c.key}
          style={{ width: c.width + 'px' }}
          onClick={() => this.columnSort(c.key)}
          className={className}
        >
          {!c.disabledTooltip && (
            <Tooltip placement='top' title={c.enableFiltering ? null : c.name}>
              {content}
            </Tooltip>
          )}
          {c.disabledTooltip && content}
        </th>
      )
    })

    let actionsTh = null
    if (
      typeof this.props.onDataEdit !== 'undefined' ||
      typeof this.props.onDataDelete !== 'undefined' ||
      typeof this.props.additionalActions !== 'undefined'
    ) {
      actionsTh = (
        <th
          style={{ width: this.state.actionsColumnWidth + 'px' }}
          className='actions'
        />
      )
    }

    return (
      <tr>
        {content}
        {actionsTh}
      </tr>
    )
  };

  renderTableBody = (columns, data) => {
    data = data.filter(d => d.shown)

    if (data.length === 0) {
      const noDataText = this.props.noDataText
        ? this.props.noDataText
        : this.props.t('No data')
      return (
        <tr style={{ height: this.state.linesDensity + 'px' }}>
          <td
            colSpan={
              columns.length +
              (typeof this.props.onDataEdit !== 'undefined' ? 1 : 0) +
              (typeof this.props.onDataDelete !== 'undefined' ? 1 : 0)
            }
            style={{ textAlign: 'center' }}
          >
            {this.props.loading || this.state.loading
              ? this.props.t('Loading')
              : noDataText}
          </td>
        </tr>
      )
    }

    return data.map((d, index) => {
      const startIndex = this.state.linesShown * this.state.pageIndex
      const endIndex = startIndex + this.state.linesShown
      if (index < startIndex || index >= endIndex) return null

      let actions = null
      if (
        this.state.newDataPending ||
        typeof this.props.onDataEdit !== 'undefined' ||
        typeof this.props.onDataDelete !== 'undefined' ||
        this.props.additionalActions
      ) {
        let deleteDataButton = null
        if (
          (!this.props.noDataDelete) &&
          (typeof this.props.onDataDelete !== 'undefined' || d.id === -1) &&
          !this.props.hideDeleteButtonForIds.includes(d.id) &&
          (this.props.checkIfRowIsControllable !== undefined ? this.props.checkIfRowIsControllable(d) : true)
        ) {
          let title = this.props.t('Delete this data ?')

          if (this.props.deleteBody) {
            title = this.props.deleteBody
          } else if (this.props.deletePopConfirmTitle) {
            title = this.props.t(this.props.deletePopConfirmTitle)
          }

          deleteDataButton = (
            <Tooltip placement='top' title={this.props.t('Delete this data ?')}>
              <Popconfirm
                placement='top'
                okType='danger'
                title={title}
                okText={this.props.t('Yes')}
                cancelText={this.props.t('Cancel')}
                onConfirm={() => this.deleteData(d)}
              >
                <Button
                  type='danger'
                  size='small'
                  style={{ width: '34px', marginLeft: '2px', textAlign: 'center' }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    className='button-smart-table'
                  >
                    <FontAwesomeIcon icon='trash' />
                  </div>
                </Button>
              </Popconfirm>
            </Tooltip>
          )
        }
        let additionalActionsButtons = null
        if (this.props.additionalActions && d.id !== -1) {
          additionalActionsButtons = this.props.additionalActions.map((aa, index) => {
            const customClass = typeof aa.customClassCallback === 'function' ? aa.customClassCallback(d) : ''

            if (aa.popconfirm) {
              return (
                <Tooltip
                  placement='top'
                  title={typeof aa.titleCallback === 'function' ? aa.titleCallback(d) : aa.title}
                  key={index + aa.type + aa.key}
                >
                  <Popconfirm
                    placement='top'
                    okType='danger'
                    title={this.props.t('Archive this care unit?')}
                    okText={this.props.t('Yes')}
                    cancelText={this.props.t('Cancel')}
                    onConfirm={() => aa.onClick.call(window, d)}
                  >
                    <Button
                      className={customClass}
                      type={customClass === '' ? aa.type : 'default'}
                      size='small'
                      style={{ width: '34px', marginLeft: '2px', textAlign: 'center' }}
                      disabled={
                        aa.disabled ||
                        (typeof aa.disabledCallback !== 'undefined' &&
                          aa.disabledCallback(d))
                      }
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className='button-smart-table'
                      >
                        <FontAwesomeIcon icon={aa.iconName} />
                      </div>
                    </Button>
                  </Popconfirm>
                </Tooltip>
              )
            }
            return (
              <Tooltip
                placement='top'
                title={typeof aa.titleCallback === 'function' ? aa.titleCallback(d) : aa.title}
                key={index + aa.type + aa.key}
              >
                <Button
                  className={customClass}
                  type={customClass === '' ? aa.type : 'default'}
                  size='small'
                  onClick={() => aa.onClick.call(window, d)}
                  style={{ width: '34px', marginLeft: '2px', textAlign: 'center' }}
                  disabled={
                    aa.disabled ||
                    (typeof aa.disabledCallback !== 'undefined' &&
                      aa.disabledCallback(d))
                  }
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    className='button-smart-table'
                  >
                    <FontAwesomeIcon icon={aa.iconName} />
                  </div>
                </Button>
              </Tooltip>
            )
          })
        }
        actions = (
          <td style={{ textAlign: 'right' }}>
            {additionalActionsButtons}
            {deleteDataButton}
          </td>
        )
      }
      return (
        <tr
          key={d.id}
          style={{ height: this.state.linesDensity + 'px' }}
          className={d.id === -1 ? 'new-data' : ''}
          onBlur={() => this.onBlurHandler(d)}
          onFocus={() => this.onFocusHandler(d)}
        >
          {columns.map(c => {
            if (!c.shown) return null

            const className = (typeof d.dataValidation[c.key] === 'undefined' || d.dataValidation[c.key])
              ? ''
              : 'incorrect-data'

            let content = ''

            if (c.type === DATA_TYPE_ID && c.keepShown) {
              content = this.renderId(d, c.key)
            } else if (c.type === DATA_TYPE_STRING) {
              content = this.renderInput(d, c.key)
            } else if (c.type === DATA_TYPE_DATE) {
              content = this.renderDatePicker(d, c.key)
            } else if (c.type === DATA_TYPE_SELECT) {
              content = this.renderSelect(d, c.key, c.dataKey)
            } else if (c.type === DATA_TYPE_SELECTOR) {
              content = this.renderSelector(d, c.key, c)
            } else if (c.type === DATA_TYPE_BOOLEAN) {
              content = this.renderSwitch({ ...c, data: d })
            } else if (c.type === DATA_TYPE_HINTED_BOOLEAN) {
              content = this.renderHintedSwitch({ ...c, data: d })
            } else if (c.type === DATA_TYPE_BOOLEAN_TAG) {
              content = this.renderBooleanTag(d, c.key)
            } else if (c.type === DATA_TYPE_SATISFACTION_RATIO) {
              content = this.renderSatisfactionRatio(d, c.key, c)
            } else if (c.type === DATA_TYPE_IMAGE) {
              content = this.renderImage(d, c.key)
            } else if (c.type === DATA_TYPE_TAGS_LIST) {
              content = this.renderTagsList(d, c)
            } else if (c.type === DATA_TYPE_CHECK) {
              content = this.renderCheck(d, c.key, c)
            }

            return (
              <td
                key={c.key}
                className={className}
                style={{ width: c.width + 'px' }}
              >
                {content}
              </td>
            )
          })}
          {actions}
        </tr>
      )
    })
  };

  onBlurHandler = data => {
    // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
    this.timeOutId = setTimeout(() => {
      this.saveObject(data)
      this.setState({ focusedLineId: null })
    })
  };

  onFocusHandler = data => {
    if (
      data.id !== this.state.focusedLineId &&
      this.state.focusedLineId !== null
    ) {
      this.saveObject(
        this.state.data.filter(d => d.id === this.state.focusedLineId)[0]
      )
    }

    this.setState({ focusedLineId: data.id })
    clearTimeout(this.timeOutId)
  };

  renderId = (data, key) => {
    if (data[key] > 0) {
      return <div>{data[key]}</div>
    }
    return null
  }

  renderInput = (data, key) => {
    const column = this.state.columns.filter(c => c.key === key)[0]
    const content = this.getContent(data, key)
    const tooltipTitle = this.getTooltipTitle(data, column.tooltipKey ?? key)

    if (!this.props.onDataEdit && column.password) {
      return (
        <div className='disabled-data'>
          <Input.Password
            value={content}
            placeholder={column.placeholder}
            disabled={column.disabled}
          />
        </div>
      )
    }

    if (
      this.props.loading ||
      (!this.props.onDataEdit && data.id !== -1) ||
      (!isFunction(column.disabled) && column.disabled) ||
      (isFunction(column.disabled) && column.disabled(data)) ||
      (this.props.checkIfRowIsControllable !== undefined && !this.props.checkIfRowIsControllable(data))
    ) {
      return (
        <Tooltip placement='top' title={tooltipTitle}>
          <div className='disabled-data'>{content}</div>
        </Tooltip>
      )
    }

    if (column.password) {
      return (
        <Form>
          <Input
            value={content}
            placeholder={column.placeholder}
            onChange={e => this.updateDataValue(data, key, e.target.value)}
          />
        </Form>
      )
    }

    return (
      <Tooltip placement='top' title={tooltipTitle}>
        <Form autoComplete='off'>
          <Input
            autoComplete='off'
            value={content}
            placeholder={column.placeholder}
            onChange={e => this.updateDataValue(data, key, e.target.value)}
          />
        </Form>
      </Tooltip>
    )
  };

  getContent = (data, key) => {
    if (typeof key === 'function') {
      return key(data)
    }

    return data[key] === null ? '' : data[key]
  }

  getTooltipTitle = (data, key) => {
    if (typeof key === 'function') {
      return key(data)
    }

    return data[`${key}title`] ?? data[key]
  }

  renderSelect = (data, key, dataKey) => {
    const column = this.state.columns.filter(c => c.key === key)[0]
    const optionsPropName = `${key}Options`
    let selectOptions = column.options
    // check if we have the options in the data itself, this is the case for dynamic options

    if (data[optionsPropName]) {
      selectOptions = data[optionsPropName]
    }

    if (isFunction(column.disabled) && column.disabled(data) && column.displayValue) {
      return (<Input value={column.disabled(data)} disabled />)
    }

    let noDataSelected = this.props.t('Select a data')
    const value = selectOptions.filter(o => {
      if (typeof dataKey === 'function') {
        return o.id === dataKey(data)
      }

      return o.id === data[key]
    })[0]

    // readOnlyName -> is used when we want to show the selected value in the select component but this is not available in the select's options
    if (data[`${key}ReadOnlyName`]) {
      noDataSelected = data[`${key}ReadOnlyName`]
    }

    if (
      this.props.loading ||
      this.state.loading ||
      (!this.props.onDataEdit && data.id !== -1) ||
      (!isFunction(column.disabled) && column.disabled) ||
      (isFunction(column.disabled) && column.disabled(data)) ||
      data[`${key}Disabled`]
    ) {
      let content = ''
      let tooltipTitle = ''

      if (data !== null) {
        content = typeof value === 'undefined' ? noDataSelected : value.name
        tooltipTitle = typeof value === 'undefined' ? noDataSelected : value.nameTitle ?? content
      }

      return (
        <Tooltip placement='top' title={tooltipTitle}>
          <div className='disabled-data'>{content}</div>
        </Tooltip>
      )
    }

    const id = typeof value === 'undefined' ? null : value.id
    let title = noDataSelected

    if (id !== null) {
      const selectedOption = selectOptions.filter(o => o.id === id)[0]

      title = selectedOption.nameTitle ?? selectedOption.name
    }

    return (
      <Tooltip placement='top' title={title}>
        <Select
          showSearch
          value={id === null ? noDataSelected : id}
          filterOption={(input, option) =>
            canonizeString(option.props.children).indexOf(
              canonizeString(input)
            ) > -1}
          onChange={e => {
            if (e === 'NEW_ITEM' && column.enableCreation && typeof column.onCreateNewItem === 'function') {
              column.onCreateNewItem(data)
              return
            }
            this.updateDataValue(data, key, e)
          }}
        >
          {selectOptions.map(o => {
            return (
              <Option key={o.id} value={o.id} title={o.nameTitle ?? o.name}>
                {o.name}
              </Option>
            )
          })}
          {column.enableCreation && <Option value='NEW_ITEM' className='new-dropdown-option'>{column.newItemComponent || this.props.t('Create new item')}</Option>}
        </Select>
      </Tooltip>
    )
  };

  renderSelector = (data, key, column) => {
    const options = typeof column.options === 'function'
      ? column.options(data)
      : column.options

    const value = typeof column.dataKey === 'function'
      ? column.dataKey(data)
      : column.dataKey

    if (!options) {
      return (
        <Tooltip placement='top' title={value}>
          <div className='disabled-data' />
        </Tooltip>
      )
    }

    return (
      <Selector
        data={options}
        dataKey={column.optionKey}
        disabled={this.props.loading || options.length === 0}
        showArrow
        showSearch
        showEmpty
        value={options.length === 0 ? '/' : value}
        onSelect={e => {
          if (e === 'NEW_ITEM' && column.enableCreation && typeof column.onCreateNewItem === 'function') {
            column.onCreateNewItem(data)
            return
          }
          this.updateDataValue(data, key, { id: e })
        }}
      />
    )
  };

  renderDatePicker = (data, key) => {
    const column = this.state.columns.filter(c => c.key === key)[0]

    if (
      this.props.loading ||
      this.props.loading ||
      (!this.props.onDataEdit && data.id !== -1) ||
      column.disabled
    ) {
      let content = data[key] === null ? '' : data[key]

      if (column.format) {
        const date = moment(content.date ?? content)

        if (date.isValid()) {
          content = date.format(column.format)
        }
      }

      return (
        <Tooltip placement='top' title={content}>
          <div className='disabled-data'>{content}</div>
        </Tooltip>
      )
    }

    return (
      <Tooltip placement='top' title={data[key]}>
        <Form autoComplete='off'>
          <Input
            autoComplete='off'
            value={data[key]}
            placeholder={column.placeholder}
            onChange={e => this.updateDataValue(data, key, e.target.value)}
          />
        </Form>
      </Tooltip>
    )
  };

  renderHintedSwitch = column => {
    return (
      <HintedSwitch
        onChange={e => this.updateDataValue(column.data, column.key, e)}
        checked={column.data[column.key]}
        {...column}
      />
    )
  }

  renderSwitch = column => {
    return (
      <Tooltip
        placement='top'
        title={column.data[column.key] ? this.props.t('Enabled') : this.props.t('Disabled')}
      >
        <Switch
          checked={column.data[column.key]}
          size='small'
          onChange={e => this.updateDataValue(column.data, column.key, e)}
          disabled={
            this.props.loading ||
            (!this.props.onDataEdit && column.data.id !== -1) ||
            column.disabled ||
            typeof column.data[column.key + '_disabled'] !== 'undefined'
          }
        />
      </Tooltip>
    )
  }

  renderBooleanTag = (data, key) => {
    const column = this.state.columns.filter(c => c.key === key)[0]
    const isTrue = data[key] === true
    if (column.yesMessage && column.noMessage) {
      return (
        <Tooltip
          placement='top'
          title={data[key] ? this.props.t(column.yesMessage) : this.props.t(column.noMessage)}
        >
          <Tag
            color={isTrue ? 'green' : 'red'}
          >
            {this.props.t(isTrue ? 'Yes' : 'No')}
          </Tag>
        </Tooltip>
      )
    } else {
      return (
        <Tag
          color={isTrue ? 'green' : 'red'}
        >
          {this.props.t(isTrue ? 'Yes' : 'No')}
        </Tag>
      )
    }
  }

  renderSatisfactionRatio = (data, key, columnProps) => {
    let customTooltip = null

    if (typeof columnProps.customTooltipGenerator === 'function') {
      customTooltip = columnProps.customTooltipGenerator(data)
    }

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <SatisfactionRatio
          ratio={data[key]}
          forceNegative={data[key] === 0 && data[`${key}Negative`] === true}
          customTooltip={customTooltip}
          count={columnProps.countKey ? data[columnProps.countKey] : null}
          data={data}
          current={key}
        />
      </Suspense>
    )
  }

  renderImage = (data, key) => {
    if (data[key]) {
      if (data[key].includes('<svg')) {
        return <div className='smart-table-image' dangerouslySetInnerHTML={{ __html: data[key] }} />
      }
      return <img className='smart-table-image' src={data[key]} />
    }
    return null
  }

  renderTagsList = (data, column) => {
    const key = column.key
    if (data[key]) {
      return (
        <div className='tags-list-smart-table'>
          {data[key].map(item => (
            <Tooltip key={`tag-${item.entityName}-${item.id}`} placement='top' title={item.translatedName}>
              <button onClick={() => {
                if (typeof (column.onTagToggle) === 'function') {
                  column.onTagToggle(item.id, data)
                }
              }}
              >
                {!item.inactiveIcon && !item.icon && <Tag className={!item.active ? 'not-selected' : ''}>{item.translatedName}</Tag>}
                {item.active && item.icon && item.icon.includes('<svg') && <div className='smart-table-image' dangerouslySetInnerHTML={{ __html: item.icon }} />}
                {item.active && item.icon && !item.icon.includes('<svg') && <img className='smart-table-image' src={item.icon} />}
                {!item.active && item.inactiveIcon && item.inactiveIcon.includes('<svg') && <div className='smart-table-image' dangerouslySetInnerHTML={{ __html: item.inactiveIcon }} />}
                {!item.active && item.inactiveIcon && !item.inactiveIcon.includes('<svg') && <img className='smart-table-image' src={item.inactiveIcon} />}
                {!item.active && !item.inactiveIcon && item.icon && item.icon.includes('<svg') && <div className='smart-table-image not-selected' dangerouslySetInnerHTML={{ __html: item.icon }} />}
                {!item.active && !item.inactiveIcon && item.icon && !item.icon.includes('<svg') && <img className='smart-table-image not-selected' src={item.icon} />}
              </button>
            </Tooltip>
          ))}
        </div>
      )
    }
    return null
  }

  renderCheck = (data, key, columnProps) => {
    let customTooltip = null
    if (typeof columnProps.customTooltipGenerator === 'function') {
      customTooltip = columnProps.customTooltipGenerator(data)
    }
    return (
      <PositiveMatchEmailingCheck
        emailingStatus={data[key]}
        customTooltip={customTooltip}
      />
    )
  }

  columnSort = key => {
    if (this.props.disableSorting) {
      return
    }
    let sortBy = key

    if (typeof key === 'undefined') {
      if (this.state.sortBy === null) {
        const foundColumn = this.state.columns.filter(c => c.key !== 'id')
        if (foundColumn && foundColumn.length) {

        }
        sortBy = foundColumn[0].key
      } else {
        sortBy = this.state.sortBy
      }
    }

    let data = this.state.data
    let sortDirection = this.state.sortDirection
    const sortedDataType = this.state.columns.filter(c => c.key === sortBy)[0]
      .type

    // If key is given, this function is called from a column click and the data might need a sort direction change.
    // If key is not given, this function is called from a props data update and data does not need a sort direction change.
    if (this.state.sortBy === sortBy && typeof key !== 'undefined') {
      sortDirection = -this.state.sortDirection
    }

    // If the user changes column sorting, we set sorting to ASC by default.
    if (sortBy !== this.state.sortBy) {
      sortDirection = 1
    }

    /* Preparing the select options if the data type is a select, for performance issues. */
    let selectOptions = null
    if (sortedDataType === DATA_TYPE_SELECT) {
      selectOptions = this.state.columns.filter(c => c.key === sortBy)[0]
        .options
    }

    /* Preparing the format option if the data type is a date, for performance issues. */
    let dateFormat = null
    if (sortedDataType === DATA_TYPE_DATE) {
      dateFormat = this.state.columns.filter(c => c.key === sortBy)[0].format
    }

    data = data.sort((a, b) => {
      if (a.id === -1 || b.id === -1) return 1

      let returnValue = 0
      a =
        typeof a[sortBy] === 'undefined' || a[sortBy] === null ? '' : a[sortBy]
      b =
        typeof b[sortBy] === 'undefined' || b[sortBy] === null ? '' : b[sortBy]

      if (sortedDataType === DATA_TYPE_ID) {
        returnValue = parseInt(a, 10) < parseInt(b, 10) ? -1 : 1
      }

      if (sortedDataType === DATA_TYPE_STRING) {
        if (typeof a === 'number' || typeof b === 'number') {
          returnValue = parseInt(a, 10) < parseInt(b, 10) ? -1 : 1
        } else {
          returnValue = canonizeString(a).localeCompare(canonizeString(b))
        }
      }

      if (sortedDataType === DATA_TYPE_DATE) {
        const isAValid = a.date ? moment(a.date, dateFormat).isValid() : moment(a, dateFormat).isValid()
        const isBValid = b.date ? moment(b.date, dateFormat).isValid() : moment(b, dateFormat).isValid()
        if (isAValid && isBValid) {
          if (a.date && b.date) {
            returnValue = moment(a.date, dateFormat).isSameOrBefore(
              moment(b.date, dateFormat)
            )
              ? -1
              : 1
          } else {
            returnValue = moment(a, dateFormat).isSameOrBefore(
              moment(b, dateFormat)
            )
              ? -1
              : 1
          }
        }
        if (isAValid && !isBValid) returnValue = 1
        if (!isAValid && isBValid) returnValue = -1
        if (!isAValid && !isBValid) returnValue = 0
      }

      if (sortedDataType === DATA_TYPE_SELECT) {
        const optionA = selectOptions.filter(o => o.id === a)
        const optionB = selectOptions.filter(o => o.id === b)
        let nameA =
          optionA.length === 0 ? this.props.t('Unknown data') : optionA[0].name
        let nameB =
          optionB.length === 0 ? this.props.t('Unknown data') : optionB[0].name
        nameA = nameA === null ? '' : nameA
        nameB = nameB === null ? '' : nameB
        returnValue = canonizeString(nameA).localeCompare(
          canonizeString(nameB)
        )
      }

      if (sortedDataType === DATA_TYPE_BOOLEAN || sortedDataType === DATA_TYPE_BOOLEAN_TAG) {
        a = a ? '0' : '1'
        b = b ? '0' : '1'
        returnValue = a < b ? -1 : 1
      }

      if (sortedDataType === DATA_TYPE_SATISFACTION_RATIO) {
        if (a === b) {
          returnValue = 0
        } else {
          returnValue = a < b ? -1 : 1
        }
      }

      return returnValue * sortDirection
    })

    this.setState({
      sortDirection: sortDirection,
      sortBy: sortBy,
      data: data
    })
  };

  updateFilter = async filter => {
    await this.setState({ filter: filter })
    this.filterData()
  };

  filterData = async () => {
    const filter = canonizeString(this.state.filter)
    const columns = this.state.columns.filter(c => c.shown)
    let data = this.state.data

    data = data.map(d => {
      for (let i = 0; i < columns.length; i++) {
        const key = columns[i].key
        const type = columns[i].type

        if (typeof d[key] !== 'undefined' && d[key] !== null) {
          if (type === DATA_TYPE_STRING) {
            if (canonizeString(d[key]).indexOf(filter) > -1) {
              d.shown = true
              return d
            }
          }

          if (type === DATA_TYPE_SELECT) {
            const option = columns
              .filter(c => c.key === key)[0]
              .options.filter(o => o.id === d[key])
            const value =
              option.length === 0
                ? this.props.t('Unknown data')
                : option[0].name
            if (value !== null && canonizeString(value).indexOf(filter) > -1) {
              d.shown = true
              return d
            }
          }

          if (type === DATA_TYPE_DATE) {
            if (canonizeString(d[key]).indexOf(filter) > -1) {
              d.shown = true
              return d
            }
          }
        }
      }

      d.shown = false
      return d
    })

    await this.setState({ data: data })
    this.incrementPageIndex(0)
  };

  incrementPageIndex = incrementer => {
    const shownLength = this.state.data.filter(d => d.shown).length
    const maxPageIndex = Math.floor((shownLength - 1) / this.state.linesShown)
    let pageIndex = this.state.pageIndex

    pageIndex += incrementer
    if (pageIndex < 0) pageIndex = 0
    if (pageIndex > maxPageIndex) pageIndex = maxPageIndex

    this.setState({ pageIndex: pageIndex })
  };

  render () {
    // Fetching data from state.
    let columns = this.state.columns
    const data = this.state.data

    // Removing id column.
    columns = columns.filter(c => c.key !== 'id' || c.keepShown === true)

    // Rendering column filter menu.
    const hideColumnsMenuItems = columns.map((c, index) => {
      return (
        <Menu.Item key={c.key ?? index}>
          <FontAwesomeIcon icon={c.shown ? 'eye' : 'eye-slash'} />
          {c.name}
        </Menu.Item>
      )
    })

    // Preparing the table variables.
    const dataLength = this.state.data.length
    const shownLength = this.state.data.filter(d => d.shown).length
    const maxPageIndex = Math.floor((shownLength - 1) / this.state.linesShown)
    const pageIndexText =
      this.props.t('Page') +
      ' ' +
      (isNaN(this.state.pageIndex + 1) ? 1 : this.state.pageIndex + 1) +
      ' / ' +
      (isNaN(maxPageIndex + 1) ? 1 : maxPageIndex + 1)
    let entriesText =
      dataLength +
      ' ' +
      (dataLength > 1 ? this.props.t('lines') : this.props.t('line'))
    if (dataLength !== shownLength) {
      entriesText +=
        ' - ' +
        shownLength +
        ' ' +
        (shownLength > 1 ? this.props.t('results') : this.props.t('result'))
    }

    let addDataButton = null
    if (typeof this.props.onDataAdd !== 'undefined' || this.props.showAddButtonDisabled) {
      addDataButton = (
        <Button
          type='primary'
          onClick={this.props.showAddButtonDisabled ? undefined : this.handleAddData}
          disabled={
            this.state.newDataPending ||
            this.props.loading ||
            this.state.loading ||
            this.props.showAddButtonDisabled ||
            this.props.disableAddButton
          }
        >
          <FontAwesomeIcon icon='plus' />
          &nbsp;
          {this.props.addDataText
            ? this.props.addDataText
            : this.props.t('Add')}
        </Button>
      )
    }

    let secondaryButtons = null
    if (this.props.secondaryButtons && this.props.secondaryButtons.length) {
      secondaryButtons = (
        <div className={`secondary-buttons ${this.props.showSecondaryButtonsInFront ? 'in-front' : ''}`}>
          {this.props.secondaryButtons}
        </div>
      )
    }

    if (data.length === 0 && !this.props.loading && !this.state.loading && !this.props.keepTableDisplayedWhenNoData) {
      return (
        <div className='smart-table' ref='smartTable'>
          <div className='flex-row'>
            <div className='flex-fill' />
            {this.props.archivedSectorsButton && (
              <>
                {this.props.archivedSectorsButton}
                <div className='h-spacing' />
              </>
            )}
            {this.props.showSecondaryButtonsInFront && secondaryButtons}
            {addDataButton}
            {!this.props.showSecondaryButtonsInFront && secondaryButtons}
          </div>
          <div className='v-spacing' />
          <div>
            {this.props.noDataText
              ? this.props.noDataText
              : this.props.t('No data')}
          </div>
        </div>
      )
    }

    let leftActions = null
    if (data.length !== 1 || data[0].id !== -1) {
      leftActions = (
        <div className='flex-row' style={{ marginBottom: '10px' }}>
          <Form autoComplete='off'>
            <Search
              placeholder={this.props.t('Filter data')}
              onKeyUp={e => this.updateFilter(e.target.value)}
              onPaste={e => this.updateFilter(e.clipboardData.getData('Text'))}
              style={{ width: 200 }}
            />
          </Form>
          <div className='h-spacing' />
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu onClick={key => this.tableSettingsItemClick(key.key)}>
                {linesShown.map(ls => {
                  return (
                    <Menu.Item key={ls.menuKey}>
                      <FontAwesomeIcon
                        icon='check'
                        style={{
                          color:
                            this.state.linesShown === ls.value
                              ? 'inherit'
                              : 'transparent'
                        }}
                      />
                      {ls.value + ' ' + this.props.t(ls.caption)}
                    </Menu.Item>
                  )
                })}
                <Menu.Divider />
                {linesDensity.map(ld => {
                  return (
                    <Menu.Item key={ld.menuKey}>
                      <FontAwesomeIcon
                        icon='check'
                        style={{
                          color:
                            this.state.linesDensity === ld.value
                              ? 'inherit'
                              : 'transparent'
                        }}
                      />
                      {this.props.t(ld.caption)}
                    </Menu.Item>
                  )
                })}
                <Menu.Divider />
                {hideColumnsMenuItems}
              </Menu>
            }
          >
            <Tooltip placement='top' title={this.props.t('Settings')}>
              <Button>
                <FontAwesomeIcon icon='cog' />
              </Button>
            </Tooltip>
          </Dropdown>
          <div className='h-spacing' />
          {this.props.customExportButton ? this.props.customExportButton
            : this.props.removeExportButton ? undefined : (
              <Tooltip placement='top' title={this.props.t('Export data')}>
                <Button onClick={() => downloadCSV(this.generateExport())}>
                  <FontAwesomeIcon icon='file-export' />
                </Button>
              </Tooltip>
            )}
          <div className='h-spacing' />
          {this.props.ExportButtonWithRangePicker ? this.props.ExportButtonWithRangePicker : null}
          {this.props.archivedSectorsButton ? this.props.archivedSectorsButton : null}
          {this.state.isEditable && (
            <Tooltip
              placement='top'
              title={
                this.state.data.filter(d => d.dirty).length > 0
                  ? this.props.t(
                    'Some data are not saved yet. If the line you are editing does not contain any error (notified by a light red background), leaving the edition of the line (by clicking anywhere out of it) will save your changes.'
                  )
                  : this.props.t('Everything is saved.')
              }
            >
              <FontAwesomeIcon
                icon='save'
                className='save-hint'
                style={{
                  color:
                    this.state.loading ||
                      this.state.data.filter(d => d.dirty).length > 0
                      ? '#B7B7B7'
                      : '#8BC34A'
                }}
              />
            </Tooltip>
          )}
        </div>
      )
    }

    const hideTableControls =
      typeof this.props.hideTableControls !== 'undefined' &&
        this.props.hideTableControls
        ? ' singleton-data'
        : ''
    return (
      <div className={'smart-table' + hideTableControls} ref='smartTable'>
        <div
          className='flex-row hidden-sigleton-data'
          style={{ flexWrap: 'wrap' }}
        >
          {leftActions}
          <div className='h-spacing' />
          {this.props.loading || this.state.loading ? (
            <div className='loading-icon black' />
          ) : null}
          <div className='flex-fill' />
          {typeof this.props.additionalAction === 'undefined' ? null : (
            <div className='flex-row'>
              {this.props.additionalAction}
              <div className='v-spacing' />
            </div>
          )}
          {this.props.showSecondaryButtonsInFront && secondaryButtons}
          {addDataButton}
          {!this.props.showSecondaryButtonsInFront && secondaryButtons}
        </div>
        <div className='error-message'>{this.state.errorMessage}</div>
        {this.renderTable(columns, data)}
        <div className='flex-row footer hidden-sigleton-data'>
          <div>{entriesText}</div>
          <div className='flex-fill' />
          <div className='flex-row'>
            <Tooltip placement='top' title={this.props.t('First page')}>
              <Button
                type='default'
                size='small'
                disabled={maxPageIndex === 0 || this.state.pageIndex === 0}
                onClick={() => this.incrementPageIndex(-maxPageIndex)}
              >
                <FontAwesomeIcon icon='angle-double-left' />
              </Button>
            </Tooltip>
            <div className='h-spacing' />
            <Tooltip placement='top' title={this.props.t('Previous page')}>
              <Button
                type='default'
                size='small'
                disabled={maxPageIndex === 0 || this.state.pageIndex === 0}
                onClick={() => this.incrementPageIndex(-1)}
              >
                <FontAwesomeIcon icon='angle-left' />
              </Button>
            </Tooltip>
            <div className='h-spacing' />
            <div className='page-index'>{pageIndexText}</div>
            <div className='h-spacing' />
            <Tooltip placement='top' title={this.props.t('Next page')}>
              <Button
                type='default'
                size='small'
                disabled={
                  maxPageIndex === 0 || this.state.pageIndex === maxPageIndex
                }
                onClick={() => this.incrementPageIndex(1)}
              >
                <FontAwesomeIcon icon='angle-right' />
              </Button>
            </Tooltip>
            <div className='h-spacing' />
            <Tooltip placement='top' title={this.props.t('Last page')}>
              <Button
                type='default'
                size='small'
                disabled={
                  maxPageIndex === 0 || this.state.pageIndex === maxPageIndex
                }
                onClick={() => this.incrementPageIndex(maxPageIndex)}
              >
                <FontAwesomeIcon icon='angle-double-right' />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    )
  }
}

export {
  DATA_TYPE_ID,
  DATA_TYPE_STRING,
  DATA_TYPE_DATE,
  DATA_TYPE_SELECT,
  DATA_TYPE_BOOLEAN,
  DATA_TYPE_BOOLEAN_TAG,
  DATA_TYPE_SATISFACTION_RATIO,
  DATA_TYPE_IMAGE,
  DATA_TYPE_TAGS_LIST,
  DATA_TYPE_CHECK,
  DATA_TYPE_HINTED_BOOLEAN,
  DATA_TYPE_SELECTOR
}
export default connect(mapStateToProps, mapDispatchToProps)(SmartTable)
