import { createSelector } from 'reselect'

const getUserRoles = state => state.roles
const getUserManagedSectors = state => state.managedSectors

export const isLocalAdmin = createSelector(
    [getUserRoles, getUserManagedSectors],
    (roles, managedSectors) => {
        if (roles === undefined || managedSectors === undefined) {

            return false    
        }

        return roles.includes('ROLE_HOSPITAL_ADMIN') && managedSectors.length !== 0
    }
) 