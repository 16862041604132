import { notification } from 'antd'
import { requestImage, requestWithPromise } from '..'
import { DELETE, GET, POST, makeQueryStringFromObject } from '../apiHelper'
import { customRequest } from '../http'

export const getAllUsers = (user, callback) => {
  requestWithPromise(`/api/users${makeQueryStringFromObject({ 'remove-pagination': true })}`, GET, null, user)
    .then(json => {
      if (json && json.data) {
        callback(json.data)
      } else {
        console.error(json)
        notification.error({
          message: 'The users cannot be loaded correctly',
          placement: 'bottomRight'
        })
      }
    })
}

export const createUser = (body, user) => {
  return customRequest(
    user,
    { path: '/user/create', body, method: POST }
  ).then(json => json.data)
}

export const deleteUser = (userId, user, callback) => {
  requestWithPromise(`/api/User/${userId}`, DELETE, null, user)
    .then(json => {
      if (json && json.data) {
        callback(json.data)
      } else {
        console.error(json)
        notification.error({
          message: 'The users cannot be deleted correctly',
          placement: 'bottomRight'
        })
      }
    })
}

export const updateUser = (userData, user, callback, errorCallback = undefined) => {
  requestWithPromise(`/user/modify/${userData.id}`, POST, userData, user)
    .then(json => {
      if (json && json.data) {
        callback(json.data)
      } else {
        console.error(json)
        notification.error({
          message: 'The users cannot be updated correctly',
          placement: 'bottomRight'
        })

        if (errorCallback) {
          errorCallback()
        }
      }
    })
}

export const getUserSpecialities = (selectedUser, user) => {
  return requestWithPromise(`/api/users/${selectedUser.id}/school-specialities`, GET, null, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const getUserSpecialyYears = (selectedUser, user) => {
  return requestWithPromise(`/api/users/${selectedUser.id}/speciality-years`, GET, null, user)
    .then(json => {
      if (json && json.data) {
        return json.data
      }
    })
}

export const linkUserToSpeciality = (selectedUser, speciality, user, followPromiseError = false) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/school-specialities`,
    POST,
    { schoolSpeciality: speciality.id },
    user,
    followPromiseError
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const unlinkUserToSpeciality = (selectedUser, speciality, user) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/school-specialities/${speciality.id}`,
    DELETE,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const linkUserToSpecialityYear = (selectedUser, specialityYear, user, disableErrorHandler = true, followPromiseError = true) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/speciality-years`,
    POST,
    { specialityYear: specialityYear.id },
    user,
    disableErrorHandler,
    followPromiseError
  )
}

export const unlinkUserToSpecialityYear = (selectedUser, specialityYear, user) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/speciality-years/${specialityYear.id}`,
    DELETE,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getContactPersonSpecialitiesBySchool = (selectedUser, school, user) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/contact-person-school-specialities?school=${school}`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getContactPersonSpecialitiesByInstitution = (selectedUser, institution, user) => {
  return requestWithPromise(
    `/api/users/${selectedUser.id}/contact-person-speciality-years?institution=${institution}`,
    GET,
    null,
    user
  ).then(json => {
    if (json && json.data) {
      return json.data
    }
  })
}

export const getProfileImage = (user) => {
  return requestImage(
    `/api/users/${user.id}/profile-image`,
    GET,
    null,
    user
  ).then(img => {
    if (img) {
      return img
    }
  })
}

export const changePassword = (user, password, passwordConfirm, shouldModifyPassword = false) => {
  return requestWithPromise(
    '/user/reset-password',
    POST,
    {
      password,
      passwordConfirm,
      shouldModifyPassword,
      userId: user.id
    },
    user
  ).then(json => {
    if (json?.data) {
      return json.data
    }
  })
}
