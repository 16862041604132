import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popconfirm, Select, Tooltip } from 'antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { canonizeString } from '../../../utils'
import CheckboxUserTable from '../../shared/CheckboxUserTable'
import TableFooter from '../../shared/Tables/TableFooter'
import TableHeader from '../../shared/Tables/TableHeader'
import Loading from '../../../HOC/Loading'

const Option = Select.Option

const DATA_TYPE_SELECT = 0
const DATA_TYPE_CHECK_CONTACT_PERSON = 1
const DEFAULT_LINE_SHOWN = 15
const SEARCH_DEFAULT_TIMER = 500
const UNSELECT_TEXT = 'Unselect all'
const SELECT_TEXT = 'Select all'

const UserSpecialityTable = ({
  columns, data, loading, onDataAdd, onDataEdit, onDataDelete, addDataText, noDataText, selectAllText, noDataSelected, deleteText,
  titlePopConfirmText, yesText, cancelText, showAddButtonDisabled, userIsContactPerson, setMultipleUserContactPersons, setUserContactPerson
}) => {
  const [pageIndex, setPageIndex] = useState(1)
  const [search, setSearch] = useState('')
  const [checkboxList, setCheckboxList] = useState([])
  const [numberCheckedBox, setNumberCheckedBox] = useState(0)

  const availableData = useMemo(() => checkboxList.filter(d => d.name.toLowerCase().includes(search)), [checkboxList, search])

  const linesCount = useMemo(() => data.length ?? 0, [data])
  const maxPageIndex = useMemo(() => {
    return data.length % DEFAULT_LINE_SHOWN === 0 ? Math.floor(data.length / DEFAULT_LINE_SHOWN) : Math.floor(data.length / DEFAULT_LINE_SHOWN) + 1
  }, [data])
  const isDataAvailable = useMemo(() => checkboxList.length > 0 || data.length > 0, [checkboxList, data])
  const checkAllCheckboxText = useMemo(() => checkboxList.length === numberCheckedBox ? UNSELECT_TEXT : SELECT_TEXT, [checkboxList])

  const displayData = useMemo(() => {
    const minIndex = DEFAULT_LINE_SHOWN * (pageIndex - 1)

    return availableData.slice(minIndex, minIndex + DEFAULT_LINE_SHOWN)
  }, [availableData, pageIndex])
  const timer = useRef(null)

  useEffect(() => {
    if (data && data.length > 0 && !loading) {
      const checkboxList = []
      let countContactPerson = 0

      data.forEach(speciality => {
        const isCheckedContactPerson = userIsContactPerson(speciality.id)

        if (isCheckedContactPerson) {
          countContactPerson++
        }

        checkboxList.push({
          ...speciality,
          isCheckedContactPerson: isCheckedContactPerson
        })
      })

      checkboxList.sort((a, b) => {
        return b.isCheckedContactPerson - a.isCheckedContactPerson
      })

      setCheckboxList(checkboxList)
      setNumberCheckedBox(countContactPerson)
    } else {
      setCheckboxList([])
    }
  }, [data, loading])

  const addNewLine = () => {
    const newCheckboxList = [...checkboxList]
    const newSpeciality = {
      ...checkboxList[0],
      name: '',
      comment: '',
      id: null,
      isCheckedContactPerson: false
    }

    newCheckboxList.unshift(newSpeciality)
    setCheckboxList(newCheckboxList)
  }

  const handleSearchFieldChange = value => {
    if (pageIndex !== 1) {
      setPageIndex(1)
    }

    if (timer.current !== null) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => setSearch(value.trim().toLowerCase()), SEARCH_DEFAULT_TIMER)
  }

  const checkAllCheckboxContactPerson = () => {
    const newCheckboxList = [...checkboxList]
    const newChangesIndex = []

    switch (checkAllCheckboxText) {
      case SELECT_TEXT:
        Object.keys(newCheckboxList).forEach(key => {
          if (!newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = true
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox(checkboxList.length)
        setCheckboxList(newCheckboxList)
        setMultipleUserContactPersons(newChangesIndex)
        break
      case UNSELECT_TEXT:
        Object.keys(newCheckboxList).forEach(key => {
          if (newCheckboxList[key].isCheckedContactPerson) {
            newCheckboxList[key].isCheckedContactPerson = false
            newChangesIndex.push(newCheckboxList[key].id)
          }
        })

        setNumberCheckedBox(0)
        setCheckboxList(newCheckboxList)
        setMultipleUserContactPersons(newChangesIndex)
        break
      default:
        break
    }
  }

  const updateDataValue = (prevData, value) => {
    const speciality = {
      id: prevData.id,
      name: value
    }

    if (prevData.id === null) {
      onDataAdd(speciality)
    } else {
      onDataEdit(speciality)
    }
  }

  const modifyUserContactPerson = (specialityId, isChecked) => {
    if (isChecked) {
      setNumberCheckedBox(numberCheckedBox - 1)
    } else {
      setNumberCheckedBox(numberCheckedBox + 1)
    }

    setUserContactPerson(specialityId)
  }

  const handleChangeChecked = useCallback(indexSpeciality => {
    const newCheckboxList = [...checkboxList]

    newCheckboxList[indexSpeciality].isCheckedContactPerson = !checkboxList[indexSpeciality].isCheckedContactPerson

    setCheckboxList(newCheckboxList)
  }, [setCheckboxList, checkboxList])

  const renderCheckbox = useCallback((data, index) => {
    let isChecked = false
    let indexSpeciality = 0

    for (const checkIndex in checkboxList) {
      if (data.id === checkboxList[checkIndex].id) {
        isChecked = checkboxList[checkIndex].isCheckedContactPerson
        indexSpeciality = checkIndex
        break
      }
    }

    let disabled = data.id === null

    if (data.disabled) {
      disabled = data.disabled
    }

    return (
      <td key={`col_contact_person${index}`}>
        <CheckboxUserTable
          setUserContactPerson={modifyUserContactPerson}
          entityId={data.id}
          isChecked={isChecked}
          handleChangeChecked={handleChangeChecked}
          indexEntity={indexSpeciality}
          disabled={disabled}
        />
      </td>
    )
  }, [checkboxList, modifyUserContactPerson, handleChangeChecked])

  const handleIndexChange = useCallback(increment => {
    let newPageIndex = pageIndex + increment

    if (newPageIndex < 1) {
      newPageIndex = 1
    } else if (newPageIndex > maxPageIndex) {
      newPageIndex = maxPageIndex
    }

    setPageIndex(newPageIndex)
  }, [setPageIndex, maxPageIndex, pageIndex])

  return (
    <div className='interactive-table'>
      <TableHeader
        onDataAdd={addNewLine}
        onDataFilter={handleSearchFieldChange}
        addButtonText={addDataText}
        disableAdd={showAddButtonDisabled}
        disableSettings='true'
      />
      <Loading loading={loading}>
        {!isDataAvailable && (
          <span> {noDataText} </span>
        )}
        {isDataAvailable && (
          <table>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th key={index} style={{ cursor: 'default', textAlign: 'center' }}>
                      {column.name}
                    </th>
                  )
                })}
                {onDataDelete && <th style={{ width: '100px' }} />}
              </tr>
            </thead>
            <tbody>
              <tr key='row_all' className='bordered' style={{ height: '32px' }}>
                <td key='col_all_title' id='col_all_title'>
                  {selectAllText}
                </td>
                <td key='col_all_contact_person' id='col_all_contact_person'>
                  <input className='checkbox' type='checkbox' onChange={checkAllCheckboxContactPerson} checked={checkAllCheckboxText === UNSELECT_TEXT} disabled={data.disabled ?? false} />
                </td>
              </tr>
              {displayData.map((data, index) => {
                return (
                  <tr key={'row_' + index} style={{ height: '32px' }}>
                    {columns.map((column, index) => {
                      let Component = <div />

                      if (column.type === DATA_TYPE_SELECT) {
                        Component = (
                          <td key={`col_${index}`}>
                            <Tooltip placement='top' title={data.name}>
                              <Select
                                showSearch
                                value={data.name === null ? noDataSelected : data.name}
                                filterOption={(input, option) =>
                                  canonizeString(option.props.children).indexOf(
                                    canonizeString(input)
                                  ) > -1}
                                onSelect={e => updateDataValue(data, e)}
                                disabled={data.disabled ?? false}
                              >
                                {column.options.map(o => {
                                  return (
                                    <Option key={o.index} value={o.id} title={o.name}>
                                      {o.name}
                                    </Option>
                                  )
                                })}
                              </Select>
                            </Tooltip>
                          </td>
                        )
                      } else if (column.type === DATA_TYPE_CHECK_CONTACT_PERSON) {
                        Component = renderCheckbox(data, index)
                      }

                      return Component
                    })}
                    {onDataDelete && (
                      <td className='buttons'>
                        <div className='flex-row'>
                          <Tooltip
                            placement='top'
                            title={deleteText}
                            key={'action-' + deleteText}
                          >
                            {titlePopConfirmText !== undefined && (
                              <Popconfirm
                                placement='top'
                                okType='danger'
                                title={titlePopConfirmText}
                                okText={yesText}
                                cancelText={cancelText}
                                onConfirm={() => onDataDelete(data)}
                              >
                                <Button
                                  type='danger'
                                  size='small'
                                  className='button'
                                  disabled={data.disabled ?? false}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </Popconfirm>
                            )}
                          </Tooltip>
                        </div>
                      </td>
                    )}
                    {titlePopConfirmText === undefined && (
                      <Button
                        type='danger'
                        size='small'
                        className='button'
                        onClick={() => onDataDelete(data)}
                        disabled={data.disabled ?? false}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </Loading>
      {isDataAvailable && (
        <TableFooter
          pageIndex={pageIndex}
          maxPageIndex={maxPageIndex}
          linesCount={linesCount}
          onIndexChange={handleIndexChange}
        />
      )}
    </div>
  )
}

export {
  DATA_TYPE_SELECT,
  DATA_TYPE_CHECK_CONTACT_PERSON
}
export default UserSpecialityTable
