import React, { Suspense, useContext } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import routes from '../../routes'
import MaccsProfilePage from '../../Components/MACCS/ProfilePage/MaccsProfilePage'
import InternshipsManagerProvider from '../../Context/InternshipsManager/InternshipsManagerProvider'
import MaccsInternshipsManagerView from '../../Views/MACCS/MaccsInternshipsManager/MaccsInternshipsManagerView'
import AssistantsView from '../../Views/MACCS/Assistants/AssistantsView'
import SpecialitiesView from '../../Views/MACCS/Specialities/SpecialitiesView'
import MaccsInstitutionsView from '../../Components/MACCS/Institutions/MaccsInstitutionsView'
import SchoolSettings from '../../Views/shared/Settings/SchoolSettings'
import MaccsShiftManagerView from '../../Views/MACCS/ShiftsManager/MaccsShiftsManagerView'
import ContactPersonsView from '../../Views/shared/ContactPersonsView/ContactPersonsView'
import MaccsEvaluation from '../../Components/MACCS/Evaluation/MaccsEvaluation'
import { GlobalContext } from '../../Providers/GlobalProvider'
import AssistantsReportingView from '../../Views/AssistantReportingView'
import { isMoment } from 'moment'

const MaccsSchoolRouter = ({ history, onLogout }) => {
  const { setCurrentDate } = useContext(GlobalContext)

  const goToShiftManager = date => {
    history.push(routes.SHIFTS_MANAGER)

    if (isMoment(date)) {
      setCurrentDate(date)
    }
  }

  return (
    <Switch>
      <Route path={routes.INTERNSHIPS}>
        <InternshipsManagerProvider>
          <MaccsInternshipsManagerView loadShiftsManager={goToShiftManager} />
        </InternshipsManagerProvider>
      </Route>
      <Route path={routes.PROFILE_PAGE}>
        <MaccsProfilePage onLogout={onLogout} />
      </Route>
      <Route path={routes.ASSISTANTS}>
        <AssistantsView />
      </Route>
      <Route path={routes.SPECIALITIES}>
        <SpecialitiesView />
      </Route>
      <Route path={routes.INSTITUTIONS}>
        <MaccsInstitutionsView />
      </Route>
      <Route path={routes.SETTINGS}>
        <SchoolSettings />
      </Route>
      <Route path={routes.SHIFTS_MANAGER}>
        <MaccsShiftManagerView />
      </Route>
      <Route path={routes.CONTACT_PERSONS}>
        <ContactPersonsView />
      </Route>
      <Route path={routes.STUDENTS_EVALUATION}>
        <Suspense fallback={<div>Loading...</div>}>
          <MaccsEvaluation />
        </Suspense>
      </Route>
      <Route path={routes.BADGES}>
        <AssistantsReportingView />
      </Route>
    </Switch>
  )
}

export default withRouter(MaccsSchoolRouter)
