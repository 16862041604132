import React, { useCallback, useContext, useMemo } from 'react'

import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import Cookie from 'js-cookie'
import { InstitutionGroupContext } from '../../../Providers/InstitutionGroupProvider'
import { GlobalFiltersContext } from '../../../Providers/GlobalFiltersProvider'
import { PRE_ACCEPTED, getState } from '../../../utils/entities/internship'
import { getTimezoneLessMoment } from '../../../utils/momentjs'
import { ACCEPTED, AUTO_ACCEPTED, EUROPEAN_DATE, ORDER_BY, PENDING, ROLE_INSTITUTION_GROUP_ADMIN, SCHEDULE_ADDED, SCHEDULE_TO_VALIDATE, SCHEDULE_VALIDATED } from '../../../utils/constants'
import { getAssistantBirthdate, getAssistantEmail, getAssistantFirstname, getAssistantLastname, getAssistantMatricule, getAssistantPhone, getInstitutionName, getSchoolName, getSpeciality } from '../../../utils/entities/maccsInternship'
import { ACTIONS, Action } from '../../../Components/shared/DataTable/utils/actions'
import { isArray } from 'lodash'
import SelectInstitutionFilter from '../../../Components/shared/DataTable/utils/SelectInstitutionFilter'
import { Tag } from 'antd'
import { PeriodExportParameters, getDocument, getMaccsInternshipsByPeriod } from '../../../utils/api/export'
import { onError } from '../../../utils/apiHelper'
import DataTable from '../../../Components/shared/DataTable/DataTable'
import { getUser } from '../../../reducers/UserReducer'
import { getLanguageWithoutLocale } from '../../../utils/entities/user'

const mapStateToProps = state => {
  return { t: getTranslate(state.locale), user: getUser(state.getUser) }
}

const InternshipsReportingTable = ({ data, loading, metadata, parameters, user, onParametersChange, onTriggerAction, t }) => {
  const institutionGroupContext = useContext(InstitutionGroupContext)
  const { selectedInstitution, setSelectedInstitution } = useContext(GlobalFiltersContext)

  const maxHeight = useMemo(() => `calc(100vh - ${Cookie.get('switch_user_id') ? '240px' : '199px'})`, [Cookie.get('switch_user_id')])
  const columns = useMemo(() => {
    const rawColumns = [
      { title: t('State'), orderBy: ORDER_BY.STATE, key: d => getTagState(getState(d)) },
      { title: t('Start'), orderBy: ORDER_BY.START_DATE, key: d => getTimezoneLessMoment(d.startDate).format(EUROPEAN_DATE) },
      { title: t('End'), orderBy: ORDER_BY.END_DATE, key: d => getTimezoneLessMoment(d.endDate).format(EUROPEAN_DATE) }
    ]

    if (user.roles.includes(ROLE_INSTITUTION_GROUP_ADMIN)) {
      rawColumns.push({
        title: t('Institution'),
        orderBy: ORDER_BY.INSTITUTION,
        key: getInstitutionName,
        style: { maxWidth: '200px' },
        tooltip: { getter: getInstitutionName, placement: 'topLeft' }
      })
    }

    return rawColumns.concat([
      { title: t('Speciality'), orderBy: ORDER_BY.SECTOR, key: getSpeciality },
      { title: t('School'), orderBy: ORDER_BY.SCHOOL, key: getSchoolName },
      { title: t('Lastname'), orderBy: ORDER_BY.LASTNAME, key: getAssistantLastname },
      { title: t('Firstname'), orderBy: ORDER_BY.FIRSTNAME, key: getAssistantFirstname },
      { title: t('Email'), orderBy: ORDER_BY.EMAIL, key: getAssistantEmail },
      { title: t('Registration number'), orderBy: ORDER_BY.REGISTRATION, key: getAssistantMatricule },
      { title: t('Phone number'), orderBy: ORDER_BY.PHONE_NUMBER, key: getAssistantPhone },
      { title: t('Birthdate'), orderBy: ORDER_BY.BIRTHDATE, key: getAssistantBirthdate }
    ])
  }, [t, user])
  const actions = useMemo(() => [
    new Action({ title: 'Assistant sheet', icon: faGraduationCap, onClick: d => onTriggerAction(d, ACTIONS.DISPLAY_ASSISTANT_SHEET) })
  ], [onTriggerAction])
  const filters = useMemo(() => {
    const filters = []

    if (institutionGroupContext && isArray(institutionGroupContext.institutions) && institutionGroupContext.institutions.length > 0) {
      filters.push({
        key: 'institution',
        label: t('Institution'),
        component: SelectInstitutionFilter,
        props: { institutions: institutionGroupContext.institutions, value: selectedInstitution, onChange: setSelectedInstitution }
      })
    }

    return filters
  }, [t, SelectInstitutionFilter, institutionGroupContext?.institutions, selectedInstitution, setSelectedInstitution])
  const getTagState = state => {
    let stateTag = <Tag color='red'> {t('Invalid state')} </Tag>

    if (state === PENDING) {
      stateTag = <Tag color='geekblue'> {t('Pending')} </Tag>
    } else if (state === PRE_ACCEPTED) {
      stateTag = <Tag color='geekblue'> {t('Accepted')} </Tag>
    } else if (state === ACCEPTED) {
      stateTag = <Tag color='green'> {t('Accepted')} </Tag>
    } else if (state === AUTO_ACCEPTED) {
      stateTag = <Tag color='green'> {t('Accepted')} </Tag>
    } else if (state === SCHEDULE_ADDED) {
      stateTag = <Tag color='blue'> {t('Scheduled')} </Tag>
    } else if (state === SCHEDULE_VALIDATED) {
      stateTag = <Tag color='purple'> {t('Validated')} </Tag>
    } else if (state === SCHEDULE_TO_VALIDATE) {
      stateTag = <Tag color='orange'> {t('In waiting of validation')} </Tag>
    }

    return stateTag
  }
  const exportData = useCallback(format => {
    const periodParams = new PeriodExportParameters({ ...parameters.period, format })
    const params = periodParams.toBody()

    params.filters = parameters.filters
    params.startDate = params.start
    params.endDate = params.end
    params.language = getLanguageWithoutLocale(user)

    delete params.start
    delete params.end

    getMaccsInternshipsByPeriod(params, user).then(json => {
      getDocument(
        json.job,
        user,
        () => onError(t('An error has occured during the exported file retrieving'))
      )
    }).catch(response => {
      onError(t('An error has occured during the export'))
    })
  }, [parameters, user])

  return (
    <div style={{ height: maxHeight }}>
      <DataTable
        actions={actions}
        columns={columns}
        data={data}
        display={{ period: true }}
        filters={filters}
        loading={loading}
        metadata={metadata}
        parameters={parameters}
        onExport={exportData}
        onParametersChange={onParametersChange}
      />
    </div>
  )
}

export default connect(mapStateToProps)(InternshipsReportingTable)
