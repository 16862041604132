import moment from 'moment'
import { request, generalErrorHandler, requestWithPromise } from '../../utils'

const INITIAL_STATE = {
  internshipsWithMessages: [],
  newMessages: 0,
  internship: null,
  preventClosing: false,
  messagesListDisplayed: false
}

const SET_INTERNSHIPS_MESSAGES = 'SET_INTERNSHIPS_MESSAGES'
const CLEAR_INTERNSHIPS_UNREAD_LIST = 'CLEAR_INTERNSHIPS_UNREAD_LIST'
const RESET_MESSAGES_DATA = 'RESET_MESSAGES_DATA'
const READ_MESSAGE = 'READ_MESSAGE'
const READ_ALL_MESSAGES = 'READ_ALL_MESSAGES'
const SET_INTERNSHIP = 'SET_INTERNSHIP'
const SET_PREVENT_CLOSING = 'SET_PREVENT_CLOSING'
const SET_MESSAGES_LIST_DISPLAYED = 'SET_MESSAGES_LIST_DISPLAYED'
const UNREAD_MESSAGE = 'UNREAD_MESSAGE'
const UPDATE_INTERNSHIP_MESSAGES = 'UPDATE_INTERNSHIP_MESSAGES'

let fetchInterval = null

const getInternshipMessagesList = state => {
  return state
}

const getInternshipsWithMessages = state => {
  return state.internshipsWithMessages
}

const getUnreadMessages = state => {
  return state.newMessages
}

const getChatInternship = state => {
  return state.internship
}

export const getPreventClosing = state => {
  return state.preventClosing
}

const getMessagesListIsDisplayed = state => {
  return state.messagesListDisplayed
}

const fetchMessages = async (currentUser, dispatch) => {
  try {
    const { data } = await request('/internship-message/chats', 'GET', null, currentUser)
    if (data) {
      let newMessages = 0
      const internshipsList = data.map(({ internship, messages, read, startDate, endDate, firstname, lastname, institution, school }) => {
        const internshipUnreadMessages = messages - read
        newMessages += internshipUnreadMessages
        return {
          internship,
          startDate: moment(startDate.date).format('DD/MM/YY'),
          endDate: moment(endDate.date).format('DD/MM/YY'),
          studentName: lastname && firstname ? `${lastname} ${firstname}`.trim() : null,
          institution,
          school,
          hasNewMessages: internshipUnreadMessages > 0,
          unreadMessages: internshipUnreadMessages
        }
      })
      dispatch({
        type: SET_INTERNSHIPS_MESSAGES,
        internshipsList,
        newMessages
      })
    }
  } catch (err) {
    generalErrorHandler(err)
  }
}

const fetchMessagesLight = async (currentUser, dispatch, currentNumberOfNewMessages = 0) => {
  try {
    const { data } = await request('/internship-message/chats-light', 'GET', null, currentUser)
    if (data) {
      let newMessages = 0
      data.forEach(({ messages, read }) => {
        const internshipUnreadMessages = messages - read
        newMessages += internshipUnreadMessages
      })
      if (newMessages > currentNumberOfNewMessages) {
        fetchMessages(currentUser, dispatch)
      }
    }
  } catch (err) {
    generalErrorHandler(err)
  }
}

const postMessageByInternship = (message, internship, user) => {
  const body = { internship: internship.id, content: message }

  return async function postMessageByInternshipThunk (dispatch, getState) {
    requestWithPromise(
      '/internship-message/send', 'POST', body, user
    ).then(json => { })
  }
}

export const startCheckingForMessages = (currentUser) => {
  return (dispatch, getState) => {
    if (!fetchInterval) {
      fetchMessages(currentUser, dispatch)
      fetchInterval = setInterval(() => {
        fetchMessagesLight(currentUser, dispatch, getState().newMessages.newMessages)
      }, 60000)
    }
  }
}

export const stopCheckingForNewMessages = () => {
  clearInterval(fetchInterval)
  fetchInterval = null
  return {
    type: CLEAR_INTERNSHIPS_UNREAD_LIST
  }
}

export const resetMessagesData = () => {
  return {
    type: RESET_MESSAGES_DATA
  }
}

export const readMessage = (internshipId) => {
  return {
    type: READ_MESSAGE,
    internshipId
  }
}

export const readAllMessages = () => {
  return {
    type: READ_ALL_MESSAGES
  }
}

export const setInternshipForChat = (internship = null) => {
  return {
    type: SET_INTERNSHIP,
    internship
  }
}

export const setPreventClosing = (status = false) => {
  return {
    type: SET_PREVENT_CLOSING,
    payload: status
  }
}

export const toggleMessageList = dispatch => {
  return (dispatch, getState) => {
    const { messagesListDisplayed } = getState().newMessages
    dispatch({
      type: SET_MESSAGES_LIST_DISPLAYED,
      isDisplayed: !messagesListDisplayed
    })
  }
}

export const markMessageUnread = (messageId, currentUser) => {
  return async (dispatch, getState) => {
    // get the selected internship
    const { internship } = getState().newMessages
    if (internship) {
      request('/internship-message/unread', 'POST', { messageId }, currentUser)

      dispatch({
        type: UNREAD_MESSAGE,
        internshipId: internship.id
      })
    }
  }
}

export const setInternshipMessages = (internshipId, count = 0) => {
  return {
    type: UPDATE_INTERNSHIP_MESSAGES,
    payload: { id: internshipId, count }
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INTERNSHIPS_MESSAGES:
      return { ...state, internshipsWithMessages: action.internshipsList, newMessages: action.newMessages }
    case UPDATE_INTERNSHIP_MESSAGES: {
      const newMessages = state.internshipsWithMessages.map(i => {
        if (i.internship === action.payload.id) {
          i.messages = action.payload.count ?? 0
        }

        return i
      })

      return { ...state, internshipsWithMessages: newMessages }
    }
    case CLEAR_INTERNSHIPS_UNREAD_LIST:
      return { ...state, messagesListDisplayed: false, internshipsWithMessages: [], internship: null }
    case RESET_MESSAGES_DATA:
      return { ...state, internshipsWithMessages: [], newMessages: 0 }
    case READ_MESSAGE: {
      const updatedList = state.internshipsWithMessages.slice()
      let changeInTotal = 1
      const internshipToUpdated = updatedList.find(item => item.internship === action.internshipId)
      if (internshipToUpdated) {
        changeInTotal = internshipToUpdated.unreadMessages
        internshipToUpdated.unreadMessages = 0
        internshipToUpdated.hasNewMessages = false
      }
      return { ...state, internshipsWithMessages: updatedList, newMessages: state.newMessages - changeInTotal }
    }
    case READ_ALL_MESSAGES:
      return {
        ...state,
        internshipsWithMessages: state.internshipsWithMessages.map(item =>
          ({ ...item, unreadMessages: 0, hasNewMessages: false })),
        newMessages: 0
      }
    case SET_INTERNSHIP:
      return { ...state, internship: action.internship }
    case SET_PREVENT_CLOSING: {
      return { ...state, preventClosing: action.payload }
    }
    case SET_MESSAGES_LIST_DISPLAYED:
      return { ...state, messagesListDisplayed: action.isDisplayed }
    case UNREAD_MESSAGE: {
      const updatedList = state.internshipsWithMessages.slice()
      const internshipToUpdated = updatedList.find(item => item.internship === action.internshipId)
      if (internshipToUpdated) {
        internshipToUpdated.unreadMessages += 1
        internshipToUpdated.hasNewMessages = true
      }
      return { ...state, internshipsWithMessages: updatedList, newMessages: state.newMessages + 1 }
    }
    default:
      return state
  }
}
export {
  getInternshipMessagesList,
  getInternshipsWithMessages,
  getUnreadMessages,
  getChatInternship,
  getMessagesListIsDisplayed,
  postMessageByInternship
}
