import {
  DEV_API_URL, DEV_URL_PATTERN, HTTP_OK, MACCS_PROD_API_URL, MACCS_PROD_URL_PATTERN,
  MACCS_STAGING_API_URL, MACCS_STAGING_URL_PATTERN, PROD_API_URL, PROD_URL_PATTERN,
  QUOTAS_API_URL, QUOTAS_URL_PATTERN, SANDBOX_API_URL, SANDBOX_URL_PATTERN, STAGING_API_URL,
  STAGING_URL_PATTERN
} from './constants/http'
import { camelCasetoKebabCase, snakeCaseToCamelCase } from './string'

export const HTTP_BAD_REQUEST = 400
export const HTTP_UNAUTHORIZED = 401
export const HTTP_FORBIDDEN = 403
export const HTTP_NOT_FOUND = 404
export const HTTP_CONFLICT = 409
export const HTTP_INTERNAL_ERROR = 500

export const customRequest = (user, { path, method, body, parameters }, config) => {
  const token = user && user.token ? user.token : null
  const url = getApiUrl() + path + buildQueryString(user, parameters)

  const req = new window.Request(url, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    referrerPolicy: 'no-referrer-when-downgrade',
    body: body ? JSON.stringify(body) : undefined
  })

  return window.fetch(req)
    .then(response => {
      if (response.status !== HTTP_OK) {
        throw response
      }

      return response.json()
    })
}

const buildQueryString = (user, parameters) => {
  let queryString = user && user.switchUser
    ? `?_switch_user=${user.switchUser}`
    : '?'

  if (typeof parameters === 'object') {
    Object.keys(parameters).forEach(key => {
      if (queryString === '?') {
        queryString += `${key}=${parameters[key]}`
      } else {
        queryString += `&${key}=${parameters[key]}`
      }
    })
  }

  return queryString
}

export const buildUrlParemeters = parameters => {
  const urlParameters = {}

  if (typeof parameters === 'object') {
    Object.keys(parameters).forEach(key => {
      urlParameters[camelCasetoKebabCase(key)] = parameters[key]
    })
  }

  return urlParameters
}

export const retrieveMetadata = meta => {
  const rawMetadata = {}

  if (typeof meta === 'object' && typeof meta.pages === 'object') {
    Object.keys(meta.pages).forEach(key => {
      if (meta.pages[key]) {
        rawMetadata[snakeCaseToCamelCase(key)] = meta.pages[key]
      }
    })
  }

  return new Metadata(rawMetadata)
}

export const getJsonBody = body => {
  return body.getReader().read().then(({ value }) => {
    const decoded = new TextDecoder('utf-8').decode(value)

    return JSON.parse(decoded)
  })
}

const getApiUrl = () => {
  if (window.location.href.includes(PROD_URL_PATTERN)) {
    return PROD_API_URL
  }

  if (window.location.href.includes(MACCS_PROD_URL_PATTERN)) {
    return MACCS_PROD_API_URL
  }

  if (window.location.href.includes(STAGING_URL_PATTERN)) {
    return STAGING_API_URL
  }

  if (window.location.href.includes(MACCS_STAGING_URL_PATTERN)) {
    return MACCS_STAGING_API_URL
  }

  if (window.location.href.includes(SANDBOX_URL_PATTERN)) {
    return SANDBOX_API_URL
  }

  if (window.location.href.includes(DEV_URL_PATTERN)) {
    return DEV_API_URL
  }

  if (window.location.href.includes(QUOTAS_URL_PATTERN)) {
    return QUOTAS_API_URL
  }

  return STAGING_API_URL
}

export class Metadata {
  constructor ({ page, last, next, perPage, pageSize, totalPages, total } = {}) {
    this.page = page ?? 1
    this.last = last ?? 0
    this.next = next ?? 0
    this.perPage = perPage ?? 10
    this.pageSize = pageSize ?? perPage ?? 10
    this.totalPages = totalPages ?? 1
    this.total = total ?? 0
  }

  isSame ({ page, perPage, next }) {
    return this.page === page && this.perPage === perPage && this.next === next
  }

  toQueryParameters () {
    return {
      page: this.page,
      perPage: this.perPage
    }
  }
}
