import React, { useRef, useState } from 'react'

import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, notification, Tooltip } from 'antd'
import Search from 'antd/lib/input/Search'
import { getTranslate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { APP_STATES } from '../../../reducers/AppStates/actionsType'
import { getAppStates } from '../../../reducers/AppStates/actions'
import { getUser } from '../../../reducers/UserReducer'
import { checkIfJsonIsCorrect, downloadFile, generalErrorHandler, requestWithPromise } from '../../../utils'
import StringStatesSelector from '../../shared/InternshipsManager/StringStatesSelector'
import { SCHOOL_MACCS_CONTEXT } from '../../../utils/constants'
import PaginationHeader from '../../shared/InternshipsManager/PaginationHeader'
import CurrentWeekButton from '../../shared/InternshipsManager/CurrentWeekButton'
import InternshipsManagerActionsHeaderExportModal from '../../shared/InternshipsManagerActionsHeaderExportModal'
import { DATE_FORMAT_SHOWN } from '../../shared/InternshipsManager/utils/constants'
import MaccsInternshipStatesOrderBy from './MaccsInternshipStatesOrderBy'
import { HTTP_INTERNAL_ERROR } from '../../../utils/constants/http'
import MonthNavigator from './MonthNavigator'
import NewMessagesBell from '../../shared/NewMessagesNotification/NewMessagesBell'
import { exportTimeline } from '../../../utils/api/maccsInternship'
import { getLanguageWithoutLocale } from '../../../utils/entities/user'

import '../../shared/InternshipsManager/assets/internships-manager.scss'

const { SET_DATE } = APP_STATES

const IN_ADD_MODE_TEXT = 'Hide the add internship buttons'
const NOT_ADD_MODE_TEXT = 'Show the internships adding buttons'
const CURRENT_WEEK_BUTTON_TEXT = 'Go back to current week'
const EXPORT_BUTTON_TEXT = 'Export timeline'

const SEARCH_FIELD_STYLE = { width: 175 }

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser),
    currentDate: getAppStates(state).date
  }
}

const MaccsInternshipsHeader = props => {
  const {
    addMode, search, orderBy, sortDirection, state, currentDate, page, metaData,
    loading, onSearchChange, onOrderByChange, onSortDirectionChange, onStateChange,
    t, toggleAddMode
  } = props

  const [exportLoading, setExportLoading] = useState(false)
  const [displayExportModal, setDisplayExportModal] = useState(false)

  const exportTimer = useRef()
  const clearingTimeout = useRef()
  const dispatch = useDispatch()

  const handleExportTimeline = (startDate, endDate, checked, filter) => {
    setDisplayExportModal(false)
    setExportLoading(true)

    const body = {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD'),
      language: getLanguageWithoutLocale(props.user)
    }

    if (checked) {
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          if (typeof body.filter === 'undefined') {
            body.filter = {}
          }

          body.filter[key] = filter[key]
        }
      })
    }

    exportTimeline(props.user, body).then(json => {
      exportTimer.current = setInterval(() => getJobResult(json), 1500)
      clearingTimeout.current = setTimeout(() => {
        clearInterval(exportTimer.current)
        setExportLoading(false)
        notification.error({ message: props.t('Internships export failed'), placement: 'bottomRight' })
      }, 20000)
    })
  }

  const handleDateChange = date => {
    dispatch({
      type: SET_DATE,
      payload: date
    })
  }

  const getJobResult = json => {
    // Security in case of export failed to prevent crash loop
    if (!json) {
      clearInterval(exportTimer.current)
      clearTimeout(clearingTimeout.current)
      setExportLoading(false)

      notification.error({ message: props.t('Internships export failed'), placement: 'bottomRight' })

      return
    }

    requestWithPromise(`/job-result/${json.job}`, 'GET', null, props.user, { catchError: false }).then(json => {
      if (checkIfJsonIsCorrect(json)) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)

        const fileName = json.data.result

        try {
          downloadFile(
            '/spreadsheet/download/' + fileName,
            fileName,
            props.user,
            'GET'
          )
        } catch (err) {
          generalErrorHandler(err)

          notification.error({ message: props.t('Spreadsheet download failed'), placement: 'bottomRight' })
        }
      }
    }).catch(response => {
      if (response.status === HTTP_INTERNAL_ERROR) {
        clearInterval(exportTimer.current)
        clearTimeout(clearingTimeout.current)
        setExportLoading(false)

        console.error(response)
      }
    })
  }

  return (
    <div className='internships-manager-header'>
      <div className='actions-and-filters'>
        <Search
          value={search}
          placeholder={t('Filter internships')}
          onChange={e => { onSearchChange(e.target.value) }}
          style={SEARCH_FIELD_STYLE}
          className='app-tour-2'
        />
        <div className='h-spacing' />
        <StringStatesSelector onOptionSelect={onStateChange} />
        <div className='h-spacing' />
        <MaccsInternshipStatesOrderBy
          value={orderBy}
          onChange={onOrderByChange}
          onDirectionChange={onSortDirectionChange}
        />
        <NewMessagesBell />
        <div className='flex-fill' />
        {props.loadShiftsManager && (
          <Button
            type='primary'
            onClick={() => props.loadShiftsManager(currentDate)}
            disabled={props.loading}
            ghost
          >
            <FontAwesomeIcon icon={faCalendar} />&nbsp;
            {props.t('Edit schedules')}
          </Button>
        )}
        {props.user.context === SCHOOL_MACCS_CONTEXT && <div className='h-spacing' />}
        {props.user.context === SCHOOL_MACCS_CONTEXT && (
          <Tooltip
            key='internships'
            placement='top'
            title={props.t(addMode ? IN_ADD_MODE_TEXT : NOT_ADD_MODE_TEXT)}
          >
            <Button
              type='primary'
              className='app-tour-1'
              disabled={props.loading}
              onClick={toggleAddMode}
              ghost
            >
              <FontAwesomeIcon icon={faPlus} />
             &nbsp;
              {props.t('Add an internship')}
            </Button>
          </Tooltip>
        )}
      </div>
      <div className='table-modifier-buttons'>
        <PaginationHeader
          pageIndex={page}
          maxPageIndex={metaData.totalPages}
          dataType='Internship'
          dataCount={metaData.total}
          onPageChange={props.onPageChange}
        />
        <div className='flex-fill' />
        <MonthNavigator
          date={currentDate}
          dateFormat={DATE_FORMAT_SHOWN}
          onDateChange={handleDateChange}
        />
        <div className='h-spacing' />
        <CurrentWeekButton
          title={props.t(CURRENT_WEEK_BUTTON_TEXT)}
          currentDate={currentDate}
          onClick={handleDateChange}
          loading={loading}
        />
        <div className='h-spacing' />
        <Tooltip placement='top' title={props.t(EXPORT_BUTTON_TEXT)}>
          <Button
            onClick={() => setDisplayExportModal(true)}
            loading={exportLoading}
          >
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        </Tooltip>
      </div>
      {displayExportModal && (
        <InternshipsManagerActionsHeaderExportModal
          startDate={currentDate}
          filterSearch={search}
          filterState={state}
          orderBy={orderBy}
          orderDirection={sortDirection}
          weeksShownCount={48}
          onCancel={() => setDisplayExportModal(false)}
          onExport={handleExportTimeline}
          visible
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps)(MaccsInternshipsHeader)
