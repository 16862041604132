import React, { useEffect, useRef } from 'react'

import { Button, Dropdown, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getUser } from '../../reducers/UserReducer'
import { connect } from 'react-redux'
import { getJob } from '../../utils/api/export'
import { generalErrorHandler } from '../../utils'
import { getLanguageWithoutLocale } from '../../utils/entities/user'

const SUPPORTED_FORMATS = [
  { label: '.csv', value: 'csv' },
  { label: '.xlsx', value: 'xlsx' }
]
const INTERVAL_TIME = 1500

const mapStateToProps = state => {
  return {
    user: getUser(state.getUser)
  }
}

const ExportButton = ({ extraParameters, user, apiExport, apiDownload }) => {
  const jobInterval = useRef(null)

  useEffect(() => {
    return () => {
      if (jobInterval.current) {
        clearInterval(jobInterval)
      }
    }
  }, [])

  const handleExport = (e) => {
    const value = e.item.props.data
    const body = typeof extraParameters === 'object'
      ? { ...extraParameters, format: value }
      : { format: value }

    body.language = getLanguageWithoutLocale(user)

    apiExport(body, user).then(json => {
      if (json && json.status !== 'error') {
        jobInterval.current = setInterval(() => {
          getJob(json.job, user).then(json => {
            if (json && json.data) {
              clearInterval(jobInterval.current)

              const fileName = json.data.result
              try {
                apiDownload(fileName, user)
              } catch (err) {
                generalErrorHandler(err)
                clearInterval(jobInterval.current)
              }
            } else if (!json || json.status === 'error') {
              clearInterval(jobInterval.current)
            }
          })
        }, INTERVAL_TIME)
      }
    })
  }

  return (
    <Dropdown
      overlay={
        <Menu onClick={handleExport}>
          {SUPPORTED_FORMATS.map((f, index) => {
            return <Menu.Item key={index} data={f.value}>{f.label}</Menu.Item>
          })}
        </Menu>
      }
      trigger={['click']}
    >
      <Button>
        <FontAwesomeIcon icon='file-export' />
      </Button>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ExportButton)
