import React, { useContext, useEffect, useState } from 'react'
import '../../../assets/shifts-manager.scss'
import SmartTable, { DATA_TYPE_ID, DATA_TYPE_SELECT, DATA_TYPE_STRING } from '../../shared/SmartTable'
import { connect } from 'react-redux'
import FilesManager from '../../shared/FilesManager'
import { getMaccsLinkedInstitutions } from '../../../utils/api/school'
import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../reducers/UserReducer'
import { oldCreateInstitution, oldRemoveInstitution, oldUpdateInstitution } from '../../../utils/api/institution'
import { GlobalContext } from '../../../Providers/GlobalProvider'
import { isObject, recursiveIncludes } from '../../../utils'
import { ROLE_ADMIN_RESEAU, ROLE_LOCAL_SECRETARY } from '../../../utils/constants'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    user: getUser(state.getUser)
  }
}

const MaccsInstitutionsView = ({ user, t }) => {
  const { countries } = useContext(GlobalContext)

  const [premiumSchool, setPremiumSchool] = useState(false)
  const [institutions, setInstitutions] = useState([])
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      setLoading(true)
      setPremiumSchool(!!user.school.managed)
      loadInstitutions(user).then(() => setLoading(false))
    }
  }, [user])

  const loadInstitutions = user => {
    return getMaccsLinkedInstitutions(user.school.id, user)
      .then(data => {
        if (data.length > 0) {
          setInstitutions(data)
        }
      })
  }

  const handleFileManagement = institution => {
    setSelectedFiles(institution)
  }

  const isEditableRow = data => {
    if (data.id === -1) {
      return true
    }

    return recursiveIncludes(user.roles, [ROLE_LOCAL_SECRETARY, ROLE_ADMIN_RESEAU]) && !data.managed
  }

  const addInstitution = institution => {
    oldCreateInstitution(institution, user).then(() => loadInstitutions(user))
  }

  const editInstitution = institution => {
    delete institution.areas

    oldUpdateInstitution({ ...institution, country: institution.country?.id }, user).then(() => loadInstitutions(user))
  }

  const deleteInstitution = institution => {
    oldRemoveInstitution(institution, user).then(() => loadInstitutions(user))
  }

  const getColumns = () => {
    return [
      { type: DATA_TYPE_ID, key: 'id' },
      {
        type: DATA_TYPE_STRING,
        name: t('Name'),
        key: 'name'
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Address'),
        key: 'address'
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Zipcode'),
        key: 'zipcode'
      },
      {
        type: DATA_TYPE_STRING,
        name: t('City'),
        key: 'city'
      },
      {
        type: DATA_TYPE_SELECT,
        name: t('Country'),
        dataKey: d => {
          let value = null

          if (isObject(d.country)) {
            value = d.country.id
          } else if (typeof d.country === 'number') {
            value = d.country
          }

          return value
        },
        key: 'country',
        options: countries
      },
      {
        type: DATA_TYPE_STRING,
        name: t('Description'),
        key: 'description'
      }
    ]
  }

  const getAdditionalActions = () => {
    return [
      {
        iconName: 'file',
        type: 'primary',
        title: t('Manage files'),
        onClick: handleFileManagement
      }
    ]
  }

  return (
    <div>
      {loading && (
        <div className='fill-content'>
          <div className='table-loading-ring' />
        </div>
      )}
      <SmartTable
        columns={getColumns()}
        data={institutions}
        checkIfRowIsControllable={premiumSchool ? isEditableRow : undefined}
        onDataAdd={premiumSchool ? addInstitution : undefined}
        onDataEdit={premiumSchool ? editInstitution : undefined}
        onDataDelete={premiumSchool ? deleteInstitution : undefined}
        additionalActions={getAdditionalActions()}
      />
      <FilesManager
        entity={selectedFiles}
        entityName='Institution'
        onClose={() => setSelectedFiles(null)}
        userContextFiltered={false}
        preventChanges
      />

    </div>
  )
}

export default connect(mapStateToProps)(MaccsInstitutionsView)
