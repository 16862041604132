import { faBriefcase, faClinicMedical, faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, Popconfirm, Timeline, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { getUser } from '../../reducers/UserReducer'
import { getMaccsInternshipDocuments, getMaccsInternshipShifts } from '../../utils/api/maccsInternship'
import {
  downloadConvention,
  lockedMaccsInternshipDocuments
} from '../../utils/api/maccsInternshipDocument'
import { getContactPersonsSchoolSpeciality } from '../../utils/api/schoolSpeciality'
import { getContactPersonsSpecialityYear } from '../../utils/api/specialityYear'
import { internshipStates } from '../../utils/constants'
import FilesManager from './FilesManager'
import InformationDisplay from './InformationDisplay'
import LoadingSkeleton from './LoadingSkeleton'
import InternshipValidationButton from './ShiftsManager/InternshipValidationButton'
import MaccsInternshipValidation from './ShiftsManager/MaccsInternshipValidation/MaccsInternshipValidation'
import { getTimezoneLessMoment } from '../../utils/momentjs'

const mapStateToProps = state => {
  return {
    t: getTranslate(state.locale),
    getUser: getUser(state.getUser)
  }
}

const MaccsInternshipInfo = (props) => {
  const [maccsInternship, setMaccsInternship] = useState(null)
  const [openValidationDrawer, setOpenValidationDrawer] = useState(false)
  const [maccsInternshipFilesManagerOpen, setMaccsInternshipFilesManagerOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingContactPersons, setLoadingContactPersons] = useState(true)
  const [contactPersons, setContactPersons] = useState([])
  const [shifts, setShifts] = useState([])
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    if (props.maccsInternship !== null) {
      setLoading(true)
      setMaccsInternship(props.maccsInternship)
      loadContactPersons(props.maccsInternship)

      const promiseStack = []

      promiseStack.push(
        getMaccsInternshipShifts(props.maccsInternship, props.getUser)
          .then((data) => {
            setShifts(data)
          })
      )

      promiseStack.push(
        getMaccsInternshipDocuments(props.maccsInternship, props.getUser)
          .then((data) => {
            setDocuments(data)
          })
      )

      Promise.all(promiseStack).then(() => {
        setLoading(false)
      })
    }
  }, [props.maccsInternship, props.getUser])

  const loadContactPersons = (maccsInternship) => {
    const { internshipYear, schoolSpeciality } = maccsInternship
    const promiseStack = []
    let contactPersonSchoolSpeciality = []
    let contactPersonSpecialityYear = []

    setLoadingContactPersons(true)

    if (schoolSpeciality) {
      promiseStack.push(
        getContactPersonsSchoolSpeciality(schoolSpeciality, props.getUser)
          .then(data => {
            contactPersonSchoolSpeciality = data
          })
      )
    }

    if (internshipYear) {
      promiseStack.push(
        getContactPersonsSpecialityYear(internshipYear, props.getUser)
          .then(data => {
            contactPersonSpecialityYear = data
          })
      )
    }

    Promise.all(promiseStack).then(() => {
      const allContactPerson = contactPersonSchoolSpeciality.concat(contactPersonSpecialityYear)
      setContactPersons(allContactPerson)
      setLoadingContactPersons(false)
    })
  }

  const handleInternshipValidation = () => {
    const currentMaccsInternship = { ...maccsInternship }

    currentMaccsInternship.state = internshipStates.SCHEDULE_VALIDATED

    setMaccsInternship(currentMaccsInternship)

    if (props.onMaccsInternshipValidate) {
      props.onMaccsInternshipValidate(currentMaccsInternship)
    }
  }

  const handleInternshipInvalidation = newState => {
    const currentMaccsInternship = { ...maccsInternship }

    currentMaccsInternship.state = newState

    setMaccsInternship(currentMaccsInternship)

    if (props.onMaccsInternshipValidate) {
      props.onMaccsInternshipValidate(currentMaccsInternship)
    }
  }

  const lockMaccsInternshipConvention = document => {
    lockedMaccsInternshipDocuments(document, props.getUser)
      .then(json => {
        const newMaccsInternship = { ...maccsInternship }
        const newDocuments = documents.map(d => {
          if (d.id === document.id) {
            d.locked = true
          }

          return d
        })

        newMaccsInternship.documents = newDocuments
        setDocuments(newDocuments)
      })
  }

  const renderContactPersons = () => {
    if (loadingContactPersons) {
      return (
        <div className='flex-column'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='loading-icon black' />
            <div style={{ marginLeft: '4px', marginTop: '4px' }}>{props.t('Loading contact persons')}</div>
          </div>
        </div>
      )
    }

    if (!contactPersons.length) {
      return (
        <div className='flex-column'>
          {props.t('None')}
        </div>
      )
    }

    // TODO new contactPerson
    return (
      <div className='flex-column'>
        {contactPersons.length > 0 && contactPersons.map(contactPerson => <div key={contactPerson.email}>&nbsp;{`${contactPerson.lastname} ${contactPerson.firstname} - `}<a href={'mailto:' + contactPerson.email}>{contactPerson.email}</a>{contactPerson.phone !== null && contactPerson.phone !== '' ? ` - ${contactPerson.phone}` : ''}</div>)}
      </div>
    )
  }

  const renderSchedule = () => {
    if (!shifts.length > 0) {
      return (<div />)
    }

    const shiftsSort = shifts.sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate))
    })
    const dates = []
    const dateIndex = moment(maccsInternship.startDate)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .clone()
    const dateEnd = moment(maccsInternship.endDate)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .clone()

    while (!dateIndex.isAfter(dateEnd)) {
      dates.push(dateIndex.clone())
      dateIndex.add(1, 'day')
    }

    return (
      <div className='internship-schedule'>
        <div className='v-spacing' />
        <Timeline>
          {dates.map(d => {
            const shift = shiftsSort.filter(s =>
              moment(s.startDate)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .isSame(d)
            )[0]
            const day = d.format('DD/MM/YY')
            let icon = null
            let start = null
            let end = null
            let pause = null

            if (shift !== undefined) {
              if (
                moment(shift.startDate).format('DD/MM/YYYY') !==
                moment(shift.endDate).format('DD/MM/YYYY')
              ) {
                icon = <FontAwesomeIcon icon='moon' />
              }

              start = moment(shift.startDate).format('HH:mm')
              end = moment(shift.endDate).format('HH:mm')
              pause = moment()
                .hours(0)
                .minutes(0)
                .seconds(0)
                .add(shift.pause, 'minutes')
                .format('HH:mm')
            }
            return (
              <Timeline.Item
                color={
                  d.isoWeekday() === 6 || d.isoWeekday() === 7
                    ? '#E8E8E8'
                    : '#3C8DBC'
                }
                key={d.format('DD/MM/YY')}
              >
                {shift === undefined
                  ? (
                    <div className='flex-row'>
                      <b>{day}</b>&nbsp;:&nbsp;{props.t('No schedule.')}
                    </div>
                  )
                  : (
                    <div className='flex-row'>
                      <b>{day}</b>&nbsp;:&nbsp;de&nbsp;<b>{start}</b>&nbsp;à&nbsp;
                      <b>{end}</b>&nbsp;avec&nbsp;<b>{pause}</b>&nbsp;de
                      pause.&nbsp;{icon}
                    </div>
                  )}
              </Timeline.Item>
            )
          })}
        </Timeline>
      </div>
    )
  }

  const renderBody = () => {
    if (!loading && maccsInternship !== null) {
      const document = documents.length > 0
        ? documents
          .sort((d1, d2) =>
            moment(d1.updatedAt).isSameOrAfter(moment(d2.updatedAt))
              ? -1
              : 1
          )
          .filter(f => f.type === 'convention')[0]
        : undefined

      let maccsInternshipConvention = (
        <Button type='default' size='small' disabled>
          <FontAwesomeIcon icon='download' />
          &nbsp;{props.t('Download')}
        </Button>
      )

      if (document !== undefined) {
        let lockButton = (
          <Tooltip
            placement='top'
            title={props.t(
              'This internship convention has been locked, the assistant cannot edit it anymore.'
            )}
          >
            <Button type='default' size='small' disabled>
              <FontAwesomeIcon icon='lock' />
            </Button>
          </Tooltip>
        )

        if (!document.locked) {
          lockButton = (
            <Popconfirm
              placement='top'
              title={props.t(
                'Accept and definitively lock this internship convention ?'
              )}
              okText={props.t('Accept and lock')}
              onConfirm={() => lockMaccsInternshipConvention(document)}
              cancelText={props.t('Cancel')}
            >
              <Tooltip
                placement='bottom'
                title={props.t(
                  'Accept and lock this internship convention to avoid the student to edit it.'
                )}
              >
                <Button type='default' size='small'>
                  <FontAwesomeIcon icon='lock-open' />
                </Button>
              </Tooltip>
            </Popconfirm>
          )
        }

        maccsInternshipConvention = (
          <div className='flex-row'>
            <Button
              type='default'
              size='small'
              onClick={() => downloadConvention(document, props.getUser)}
            >
              <FontAwesomeIcon icon='download' />
              &nbsp;{props.t('Download')}
            </Button>
            <div className='h-spacing' />
            {lockButton}
          </div>
        )
      }

      const maccsInternshipInfo = [
        {
          id: 1,
          icon: faClinicMedical,
          title: props.t('Institution'),
          caption:
            maccsInternship.institution === null
              ? props.t('Without institution')
              : maccsInternship.institution.name
        },
        {
          id: 2,
          icon: faPeopleArrows,
          title: props.t('Contact persons'),
          caption: renderContactPersons()
        },
        {
          id: 3,
          icon: faBriefcase,
          title: props.t('Working regime'),
          caption: maccsInternship.workingRegime === null
            ? props.t('Without working regime')
            : props.t('Working regime') + ' : ' + props.t(maccsInternship.workingRegimeName)
        },
        {
          id: 4,
          icon: 'calendar-alt',
          title: props.t('Dates'),
          caption:
            props.t('From') +
            ' ' +
            getTimezoneLessMoment(maccsInternship.startDate).format('DD MMMM YYYY') +
            ' ' +
            props.t('to') +
            ' ' +
            getTimezoneLessMoment(maccsInternship.endDate).format('DD MMMM YYYY')
        },
        {
          id: 5,
          icon: 'file',
          title: props.t('Internship convention'),
          caption: <div>{maccsInternshipConvention}</div>
        },
        {
          id: 6,
          icon: 'file',
          title: props.t('Other internship documents'),
          caption: (
            <div>
              <Button
                type='default'
                size='small'
                onClick={() => setMaccsInternshipFilesManagerOpen(true)}
              >
                <FontAwesomeIcon icon='paperclip' />
                &nbsp;{props.t('See')}
              </Button>
            </div>
          )
        },
        {
          id: 7,
          icon: 'calendar-alt',
          title: props.t('Schedule'),
          caption: renderSchedule()
        }
      ]

      return (
        <div className='flex-column'>
          <InformationDisplay information={maccsInternshipInfo} />
        </div>
      )
    } else {
      return (
        <LoadingSkeleton />
      )
    }
  }

  const renderDrawer = () => {
    let title = props.t('Internship sheet')

    if (maccsInternship != null) {
      let validateButtonTooltip = props.t('Validate internship')

      switch (maccsInternship.state) {
        case internshipStates.SCHEDULE_TO_VALIDATE:
          validateButtonTooltip = props.t('Internship to validate, student uploaded signed document')
          break
        case internshipStates.SCHEDULE_VALIDATED:
          validateButtonTooltip = props.t('Internship validated')
          break
      }

      title = (
        <div className='internship-sheet-title'>
          <span>
            {props.t('Internship sheet')}
            {loading
              ? (
                <div className='loading-icon black' style={{ marginTop: '1px' }} />
              )
              : null}
          </span>
          <span className='internship-validation-button-custom'>
            <InternshipValidationButton
              validateButtonTooltip={validateButtonTooltip}
              onDisplayValidationDrawer={() => setOpenValidationDrawer(true)}
              internship={maccsInternship}
            />
          </span>
        </div>
      )
    }

    return (
      <Drawer
        title={title}
        width='550px'
        onClose={props.onClose}
        visible={props.maccsInternship !== null}
      >
        {renderBody()}
        {openValidationDrawer &&
          <MaccsInternshipValidation
            maccsInternship={maccsInternship}
            onClose={() => setOpenValidationDrawer(false)}
            onValidate={handleInternshipValidation}
            onInvalidate={handleInternshipInvalidation}
            bottom
          />}
        <FilesManager
          width='550px'
          entity={maccsInternshipFilesManagerOpen ? maccsInternship : null}
          entityName='MaccsInternship'
          onClose={() => setMaccsInternshipFilesManagerOpen(false)}
          fileType='document'
        />
      </Drawer>
    )
  }

  return (renderDrawer())
}

export default connect(mapStateToProps)(MaccsInternshipInfo)
